import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import "../Print/print.css"
import { PiDownloadThin } from "react-icons/pi";
import { IoMdMail } from "react-icons/io";
import { FiPrinter } from "react-icons/fi";
import moment from 'moment';
import Logo from "../../Assets/Images/Pro_Logo-White.png"
import ApiService from '../../services/apiServices';
import { useCurrency } from '../../../INVENTORY/Layouts/currencyContext';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toWords } from 'number-to-words';
const SalesInvoicePrint = ({ }) => {
    const location = useLocation();
    const CompareData = location.state;
    const [Items, setItems] = useState([]);
    const [suppliers, setSupplier] = useState();
    
    const currentDatetimeUTC = new Date().toISOString();
    const { currencyPrecision } = useCurrency();
    const totalAmount = Items.reduce((sum, row) => sum + (row?.amount), 0)
    useEffect(() => {
        fetchSupplierData()
        setItems(CompareData?.invoiceRegDetails)
    }, [location?.state]);
    const fetchSupplierData = async () => {
        try {
            const apiService = new ApiService()
            let param = `?CustomerName=${CompareData?.vendorName}`
            const response = await apiService.getApiParamater('GET_CUSTOMER', param);
            setSupplier(response?.data?.result[0]);
        } catch (error) {
            console.error("Error fetching Supplier data:", error);
        }
    };
    const handleBackClick = () => {
        window.history.back();
    };
    const handlePrintPDF = async () => {
        const input = document.getElementById('mainboxdsn');
        if (input) {
            const canvas = await html2canvas(input, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF("landscape");
            pdf.addImage(imgData, 'PNG', 10, 20, 275, 170);
            pdf.save(`Invoice-${CompareData?.dnInvoiceNo}.pdf`);
        } else {
            console.error("Element not found: mainboxdsn");
        }
    };
    const handlePrintInvoice = async () => {
        const input = document.getElementById('mainboxdsn');
        if (input) {
            const canvas = await html2canvas(input, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
    
            const printWindow = window.open("", "", "height=600,width=800");
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print Invoice</title>
                        <style>
                            body, html {
                                margin: 0;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                                background-color: white;
                            }
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        </style>
                    </head>
                    <body>
                        <img src="${imgData}" />
                    </body>
                </html>
            `);
    
            printWindow.document.close();
            printWindow.onload = () => printWindow.print();
        } else {
            console.error("Element not found: mainboxdsn");
        }
    };
    return (
        <div className='container p-lg-5'>
            <div className='d-flex justify-content-between py-5'>
                <div className='' onClick={handleBackClick} ><button className='prntbtn'> <MdKeyboardDoubleArrowLeft size={20} /> Back</button></div>
                <div className=''><button className='prntbtn'><IoMdMail size={20} /> Send To Mail</button></div>
                <div className=''><button className='prntbtn' onClick={handlePrintInvoice}> <FiPrinter size={20} /> Print</button></div>
                <div className=''><button className='downbtn' onClick={handlePrintPDF}> <PiDownloadThin size={20} /></button></div>
            </div>
            <div className='mainboxdsn' id="mainboxdsn">
                <div className='Salprinthead'>
                    <div className='d-flex justify-content-between'>
                        <div className='center'>
                            <img src={Logo} className="printlogo" alt="Logo" style={{ height: "30px" }} />
                        </div>
                        <div className='mainhead'>Customer Invoice</div>
                        <div className='d-flex align-items-end'>Date: {moment(CompareData?.docDate).format('ll')}</div>
                    </div>
                </div>
                <div className='printboxIn'>
                    <div className=' pt-2 pb-4 d-flex gap-4'>
                        <div className='detailbox1 w-50'>
                            <div className='detailboxhead'>Customer Details</div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Date</div>
                                <div className='col'>{moment(CompareData?.approvalDate).format('ll')}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Customer NO</div>
                                <div className='col'>{suppliers?.mainContactNo}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Customer Name :</div>
                                <div className='col'>{suppliers?.title + ("  ") + suppliers?.customer_Name || "---"}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Customer Nationality</div>
                                <div className='col'>{suppliers?.nationality || "---"}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Project Name</div>
                                <div className='col'>{suppliers?.reqType || "---"}</div>
                            </div>
                        </div>
                        <div className='detailbox2 w-50'>
                            <div className='detailboxhead'>Invoice Details</div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Invoice No</div>
                                <div className='col'>{CompareData?.dnInvoiceNo || "---"}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Invoice Date</div>
                                <div className='col'>{moment(CompareData?.modifiedOn).format('ll')}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Sale Order No</div>
                                <div className='col'>{CompareData?.sOrderNo || "---"}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Due Date</div>
                                <div className='col'>{moment(CompareData?.dueDate).format('ll')}</div>
                            </div>
                            <div className='detailbx m-0 row'>
                                <div className='col-1'></div>
                                <div className='col'>Unit No</div>
                                <div className='col'>{CompareData?.rcNo}</div>
                            </div>
                        </div>
                    </div>
                    <table>
                        <thead className='dynoTable' style={{ backgroundColor: '#5B93FF', color: '#fff' }}>
                            <tr>
                                <th>Sr no</th>
                                <th>Item No</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>UOM</th>
                                <th>Unit Price</th>
                                <th>vat Perc</th>
                                <th>Vat Amount</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Items?.map((row, index) => (
                                <tr key={index}>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "106px" }} type="text" value={index + 1} disabled /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "120px" }} disabled value={row.itemCode} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "150px" }} disabled value={row.qty} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "150px" }} disabled value={row.uom} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} disabled value={row.unitPrice.toFixed(currencyPrecision) || 0} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} disabled value={row.vatPerc || 0} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "130px" }} disabled value={row.vatAmount.toFixed(currencyPrecision) || 0} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "150px" }} disabled value={(row.amount).toFixed(currencyPrecision) || 0} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-around printhead2 rounded-0' style={{ backgroundColor: "rgba(192, 152, 229, 0.4399999976158142)" }}>
                        <div className='text-dark pt-1'><h6>Total Invoice Amount</h6></div>
                        <div className='text-dark pt-1'><h6>{totalAmount} ({toWords(totalAmount)})</h6></div>
                    </div>
                </div>
                <div className='printhead2 pb-3 pt-5'>
                    <div className='d-flex justify-content-around detailboxhead rounded-0'>
                        <div className='pt-1'><h6>Bank Details</h6></div>
                        <div className='pt-1'><h6>Bank Name</h6></div>
                    </div>
                    <div className=' row d-flex justify-content-between'>
                        <div className='col-2'>
                            <div className='itmKIns center'>Account No</div>
                            <div className='itmKIns center'>{suppliers?.accountCode}</div>
                        </div>
                        <div className='col-2'>
                            <div className='itmKIns center'>Account Name</div>
                            <div className='itmKIns center'>{suppliers?.accountCode}</div>
                        </div>
                        <div className='col-2'>
                            <div className='itmKIns center'>IBAN Number</div>
                            <div className='itmKIns center'>{suppliers?.accountCode}</div>
                        </div>
                        <div className='col-2'>
                            <div className='itmKIns center'>Swift Code</div>
                            <div className='itmKIns center'>{suppliers?.sgCode}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesInvoicePrint;
