import React, { useState, useEffect } from 'react';
import ApiService from '../../../INVENTORY/services/apiService';
import SalesApiService from '../../services/apiServices';

const InvItemsGrid = ({ DrnList, handleItemData }) => {
    const [rows, setRows] = useState([{
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        unitPrice: '',
        InvQty: '',
        balQty: '',
        freeQty: '',
        amount: '',
        discount: '',
        discountAmount: '',
        convRate: "",
        vat: 0,
        vatDec: '',
        vatAmount: '',
        expdate: '',
        inventoryAccountCode: "",
        inventoryAccountName: "",
    }]);
    const [apiData, setApiData] = useState([]);
    const apiService = new ApiService();
    const salesApiService = new SalesApiService();
    const netAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVat = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    const totalDiscountAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.discountAmount) || 0), 0)
    const totalDiscount = rows.reduce((sum, row) => sum + (parseFloat(row?.discount) || 0), 0)
    const calculatedTotal = rows.reduce((sum, row) => sum + (parseFloat(row?.amount - row?.vatAmount) || 0), 0)
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const selectedDrnList = DrnList.filter(item => item.selected === 1);
        if (selectedDrnList.length > 0) {
            getItemsForSelectedDrns(selectedDrnList);
        }
    }, [DrnList]);

    const getItemsForSelectedDrns = async (selectedDrnList) => {
        try {
            const allItems = await Promise.all(selectedDrnList?.map(async (detail) => {
                const param = `?DNNo=${detail.dnNo}&Mode=0`;
                const res = await salesApiService.getApiParamater(`GET_DRN_SO_ITEM`, param);
                const DNparam = `?DNNo=${detail.dnNo}`;
                const DNres = await salesApiService.getApiParamater(`GET_DN`, DNparam);
                const formattedRows = res?.data?.result?.map((item, index) => {
                    const selectedItem = apiData?.find(item => item.itemDescription === item.itemDescription);
                    let DN = DNres.data.result[0]
                    let amount = item.unitPrice * item.qty
                    const perAmount = (amount / 100) * item?.vatPerc
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = DN?.vatInclusive ? excludedAmount : amount;
                    
                    return {
                        srno: item?.srno,
                        dnNo: detail?.dnNo,
                        recordId: item?.itemCode,
                        ItemNo: item?.itemCode,
                        itemDescription: item?.itemDescription,
                        specification: item?.specification,
                        qty: item?.qty,
                        uom: item?.uom,
                        unitPrice: item?.unitPrice,
                        InvQty: selectedItem?.convToInvUOM * item?.qty,
                        balQty: item?.balQty,
                        freeQty: 0,
                        amount: calculatedAmount,
                        convRate: selectedItem?.convToInvUOM,
                        vat: item?.vatPerc,
                        vatDec: item?.vatDesc,
                        vatAmount: perAmount,
                        inventoryAccountName: selectedItem.acName,
                        inventoryAccountCode: selectedItem.acCode,
                    }
                }).filter(row => row !== null);
                
                return (formattedRows)
            }));
            const flattenedItems = allItems.flat();
            setRows(flattenedItems);
            handleItemData(flattenedItems);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            const selecteditem = response.data.result?.filter(rec => rec.exp9 === '1')
            if (response?.data?.result?.length > 0) {
                setApiData(selecteditem);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div>
            <div className='table-container mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Price</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                            <th>Ext Amount</th>
                            <th>Exp Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) && rows.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={index + 1} style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row?.ItemNo} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row?.itemDescription} style={{ width: "150px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row.specification} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row.qty} style={{ width: "90px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row.uom} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row.InvQty} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row.unitPrice} style={{ width: "90px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row.unitPrice} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row.discount} style={{ width: "100px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row?.vat} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row?.vatAmount} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={row?.amount} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="text" value={0} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input disabled className='p-1' type="date" value={row?.expdate} style={{ width: "120px" }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>{`${totalDiscount}` || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{calculatedTotal || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>{totalDiscountAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVat || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{netAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default InvItemsGrid;
