import React, { useEffect, useState, useRef } from 'react';
import { FaAngleLeft } from "react-icons/fa6";
import Select from 'react-select';
import DRNGrid from './DRNGrid';
import InvItemsGrid from './itemGrid';
import { useLocation, useNavigate } from 'react-router-dom';
import InvApiService from '../../../INVENTORY/services/apiService';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
const CustomerInvView = () => {
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [POTab, setPOTab] = useState(true);
    const [DRNTab, setDRNTab] = useState(false);
    const [CostTab, setCostTab] = useState(false);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [ItemsData, setItemsData] = useState([]);
    const [DrnList, setDrnList] = useState([]);
    const [SoList, setSoList] = useState([]);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [activeTab, setActiveTab] = useState(0);
    const [invNo, setInvNo] = useState();
    const InvapiService = new InvApiService();
    const location = useLocation();
    const itemData = location.state;
    useEffect(() => {
        const item = location.state;
        const getDocId = async () => {
            try {
                setInvNo(item?.dnInvoiceNo)
                setSelectedCurency({ label: item?.currencyCode, value: item?.currencyCode })
                setSoList({ label: item?.sOrderNo, value: item?.sOrderNo })
                
                setFormData({
                    sOrderNo: item?.sOrderNo,
                    SOType: "",
                    DocumentDate: item?.approvalDate,
                    deliveryDate: getCurrentLocalDatetime(),
                    InvoiceDate: item?.docDate,
                    DueDate: item?.dueDate,
                    TaxInvoice: item?.dnInvoiceRef,
                    Narration: item?.contractNo,
                    CostCentre5: item?.costCenter5,
                    exchangeRate: item?.exchangeRate,
                    CostInvoiceNo: item?.costCenter5,
                    costCenter1: { label: item?.costCenter1, value: item?.costCenter1 },
                    costCenter2: { label: item?.costCenter2, value: item?.costCenter2 },
                    costCenter3: { label: item?.costCenter3, value: item?.costCenter3 },
                    costCenter4: { label: item?.costCenter4, value: item?.costCenter4 },
                    customerId: { label: item?.vendorId, value: item?.vendorId },
                    customerName: { label: item?.vendorName, value: item?.vendorName },
                    paymentTerm: { label: item?.terms, value: item?.terms },
                    selectedSale: { label: item?.sOrderNo, value: item?.sOrderNo },
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        if (item?.dnInvoiceNo) {
            getDocId()
        }
    }, [location?.state]);
    useEffect(() => {
        getDocId()
    }, [])
    const getDocId = async () => {
        try {
            let param = `?isActive=true`
            let res = await InvapiService.getApiParamater('GET_CURRENCY_MASTER', param)
            const filteredDenominations = res?.data?.result?.find(currency => currency?.isDefault === true);
            if (filteredDenominations) {
                setSelectedCurency({ label: filteredDenominations.code, value: filteredDenominations });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    };
    const [formData, setFormData] = useState({
        sOrderNo: "",
        SOType: "",
        DocumentDate: getCurrentLocalDatetime(),
        deliveryDate: getCurrentLocalDatetime(),
        InvoiceDate: getCurrentLocalDatetime(),
        DueDate: getCurrentLocalDatetime(),
        TaxInvoice: "",
        Narration: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        exchangeRate: "",
        CostInvoiceNo: "",
        customerId: "",
        customerName: "",
        OrderType: [],
        paymentTerm: [],
        selectedSale: [],
    });
    const handleBackClick = () => {
        window.history.back();
    };
    const handleItemData = (data) => {
        setItemsData(data);
    };
    const handleDRNData = (data) => {
        setDrnList(data);
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    };
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'>Customer Invoice</h5>
                <div className='d-flex gap-3'>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setPOTab(!POTab)}>
                    <div className='h6 pt-1'>Invoice Details</div>
                </div>
                <div className={`slide-content ${POTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${POTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceNO" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    className="form-Input w-70"
                                    value={invNo}
                                    disabled
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="deliveryDate" className="form-label  pt-1 start">Date </label>
                                <input className='form-Input w-70'
                                    disabled
                                    id="DocumentDate" type='datetime' name="DocumentDate"
                                    value={moment(formData.DocumentDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="SOType" className="form-label  pt-1 start">SO Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled
                                    id="SOType"
                                    name="SOType"
                                    value={formData.SOType}

                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="InvoiceDate" className="form-label  pt-1 start">Invoice Date</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    id="InvoiceDate" type='datetime' name="InvoiceDate"
                                    value={moment(formData.InvoiceDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Customer Name</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerName}
                                    cacheOptions
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="PaymentTerm" className="form-label  pt-1 start">Payment Term</label>
                                <Select
                                    className="w-70 p-0"
                                    value={formData?.paymentTerm}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row  pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="inventoryAccountCode" className="form-label  pt-1 start">Sale Order No *</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    isDisabled
                                    value={formData.selectedSale}
                                    className="w-70 p-0"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="CostInvoiceNo" className="form-label  pt-1 start">Invoice No</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="CostInvoiceNo"
                                    name="CostInvoiceNo"
                                    disabled
                                    value={formData.CostInvoiceNo}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="DueDate" className="form-label  pt-1 start">Due Date</label>
                                <input
                                    className="form-Input w-70"
                                    disabled
                                    id="DueDate" type='datetime' name="DueDate"
                                    value={moment(formData.DueDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="TaxInvoice" className="form-label  pt-1 start">Tax Invoice No ( KRA )</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="TaxInvoice"
                                    disabled
                                    name="TaxInvoice"
                                    value={formData.TaxInvoice}
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="Currency" className="form-label  pt-1 start">Currency</label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    value={SelectedCurrency}
                                    isDisabled
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                />
                            </div>
                            <div className="d-flex justify-content-between col">
                                <label htmlFor="EXRate" className="form-label  pt-1 start">EX Rate</label>
                                <input
                                    type="number"
                                    className="form-Input w-70"
                                    disabled
                                    value={formData.exchangeRate}
                                    id="exchangeRate"
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="d-flex justify-content-between col-4">
                                <label htmlFor="Narration" className="form-label  pt-1 start">Narration</label>
                                <textarea
                                    type="text"
                                    className="form-Input w-70"
                                    id="Narration"
                                    disabled
                                    name="Narration"
                                    value={formData.Narration}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setDRNTab(!DRNTab)}>
                    <div className='h6 pt-1'>Dn Details</div>
                </div>
                <div className={`slide-content ${DRNTab ? 'open' : 'closed'}`}>
                    <div className="border-box2">
                        <div className="grid-1">
                            <DRNGrid formData={formData} handleDRNData={handleDRNData} itemData={itemData} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label me-2">Property Group</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter1}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label me-2">Property</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter2}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label me-2">Department</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter3}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label me-2">Outlet</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter4}
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label me-2">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 mb-3">
                {gridVisibility[0] && (
                    <InvItemsGrid DrnList={DrnList} handleItemData={handleItemData} />
                )}
            </div>
        </div>
    );
};
export default CustomerInvView; 