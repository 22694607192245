import React, { useState } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, NavLink, useLocation } from "react-router-dom";
import YourLogo from "../../Assets/Images/Pro_Logo.png";
import "../../Assets/Style/style.css"
import "./sidebar.css"
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineStock, AiOutlineBulb, AiOutlineFileText, AiOutlineAccountBook } from "react-icons/ai";
import { FaTruck, FaFileInvoiceDollar, FaUndo, FaClipboardCheck } from "react-icons/fa";
import { GiSellCard } from "react-icons/gi";
import { FaEnvelopeOpenText, FaNoteSticky } from "react-icons/fa6";
import { TbFileInvoice, TbRepeat, TbTruckDelivery, TbUpload } from "react-icons/tb";

const SalesSideBar = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [showSalesSegment, setSalesSegment] = useState(false);
  const saleOrder = location.pathname.startsWith('/Sales/SaleOrder');
  const DRN = location.pathname.startsWith('/Sales/DRN');
  const CustomerInv = location.pathname.startsWith('/Sales/Customer/Invoice');
  const salesReturn = location.pathname.startsWith('/Sales/return');
  const salesPosting = location.pathname.startsWith('/Sales/Posting');


  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <ProSidebar collapsed={collapsed}>
        {collapsed ?
          <a href="/" className="navbar-brand start"><img src={YourLogo} alt="Logo" style={{ height: "35px", paddingTop: "8px", paddingBottom: "8px" }} /></a> :
          <a href="/" className="navbar-brand start"><img src={YourLogo} alt="Logo" style={{ height: "30px" }} /></a>
        }
       <Menu className="mt-2">
            <MenuItem
                icon={<AiOutlineStock size={23} />}
                active={location.pathname.startsWith('/Sales/Quotation')}
            >
                <NavLink to="/Sales/Quotation">
                    <div>Quotation</div>
                </NavLink>
            </MenuItem>
            <MenuItem
                icon={<AiOutlineBulb size={23} />}
                active={location.pathname.startsWith('/Sales/SaleOrder')}
            >
                <NavLink to="/Sales/SaleOrder">
                    <div className={`submenu ${location.pathname === "/Sales/SaleOrder" ? 'subactive' : ''}`}>Sales Order</div>
                </NavLink>
            </MenuItem>

            <MenuItem
                icon={<TbTruckDelivery size={23} />}
                active={location.pathname.startsWith('/Sales/DRN')}
            >
                <NavLink to="/Sales/DRN">
                    <div className={`submenu ${location.pathname === "/Sales/DRN" ? 'subactive' : ''}`}>Delivery Note</div>
                </NavLink>
            </MenuItem>
            <MenuItem
                icon={<TbFileInvoice size={23} />}
                active={location.pathname.startsWith('/Sales/Customer/Invoice')}
            >
                <NavLink to="/Sales/Customer/Invoice">
                    <div className={`submenu ${location.pathname === "/Sales/Customer/Invoice" ? 'subactive' : ''}`}>Invoice</div>
                </NavLink>
            </MenuItem>
            <MenuItem
                icon={<TbRepeat size={23} />}
                active={location.pathname.startsWith('/Sales/Return')}
            >
                <NavLink to="/Sales/Return">
                    <div className={`submenu ${location.pathname === "/Sales/Return" ? 'subactive' : ''}`}>Sales Return</div>
                </NavLink>
            </MenuItem>

            <MenuItem
                icon={<TbUpload size={23} />}
                active={location.pathname.startsWith('/Sales/Posting')}
            >
                <NavLink to="/Sales/Posting">
                    <div className={`submenu ${location.pathname === "/Sales/Posting" ? 'subactive' : ''}`}>Posting</div>
                </NavLink>
            </MenuItem>
        </Menu>
        <div
          onClick={handleToggle}
          className="sidebar-toggle-btn"
          style={{ left: collapsed ? '100px' : '300px' }}
        >
          <RxHamburgerMenu size={24} />
        </div>
      </ProSidebar>
    </div>
  );
};

export default SalesSideBar;
