let BASEURL = 'https://tradingapi.qfinity.net'
export const endPoint = {
    // Sale Order
    CREATE_SALE: `${BASEURL}/api/SaleOrder/Create`,
    UPDATE_SALE: `${BASEURL}/api/SaleOrder/Update`,
    GET_SALE: `${BASEURL}/api/SaleOrder/Get`,
    // Quotation
    CREATE_QUOT: `${BASEURL}/api/QuotationMaster/Create`,
    UPDATE_QUOT: `${BASEURL}/api/QuotationMaster/Update`,
    GET_QUOT: `${BASEURL}/api/QuotationMaster/Get`,
    // Delivery Note
    CREATE_DN: `${BASEURL}/api/DeliveryNote/Create`,
    UPDATE_DN: `${BASEURL}/api/DeliveryNote/Update`,
    GET_DN: `${BASEURL}/api/DeliveryNote/Get`,
    GET_DN_SO: `${BASEURL}/api/DeliveryNote/GetSOrderNoForDeliverNote`,
    GET_DN_SO_DETAIL: `${BASEURL}/api/DeliveryNote/GetSaleOrderDetailsForDeliveryNote`,
    GET_DN_SO_ITEM: `${BASEURL}/api/DeliveryNote/GetSOrderNoForDeliverNote`,
    //Customer 
    CREATE_CUSTOMER: `${BASEURL}/api/ECustomerMaster/Create`,
    UPDATE_CUSTOMER: `${BASEURL}/api/ECustomerMaster/Update`,
    GET_CUSTOMER: `${BASEURL}/api/ECustomerMaster/Get`,
    // Delivery Note
    CREATE_INV: `${BASEURL}/api/Invoice/Create`,
    UPDATE_INV: `${BASEURL}/api/Invoice/Update`,
    GET_INV: `${BASEURL}/api/Invoice/Get`,
    GET_SO_DRN: `${BASEURL}/api/Invoice/GetDeliveryNoteInfoOfSaleOrder`,
    GET_DRN_SO_ITEM: `${BASEURL}/api/Invoice/GetDNDetailsForInvoice`,
    // SaleReturn

    CREATE_SALE_RETURN: `${BASEURL}/api/SaleReturn/Create`,
    UPDATE_SALE_RETURN: `${BASEURL}/api/SaleReturn/Update`,
    GET_SALE_RETURN: `${BASEURL}/api/SaleReturn/Get`,

}