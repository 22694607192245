import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getRole } from '../Redux/selecters/index'
import { createHubConnection } from '../POS/services/signalRService';
import PlainLayout from '../POS/component/pages/SideBar/plainLayout';
// import Login from '../POS/component/pages/user/Login';
import Signup from '../POS/component/pages/user/signup';
import CustomerMenu from '../POS/Customer/Menu/customerMenu';
import AddtoCart from '../POS/Customer/Cart/cart';
import CardDetails from '../POS/Customer/Payment/cardDetails';
import WaitPage from '../POS/Customer/Payment/waitPage';
import CashierLayout from '../POS/Cashier/components/cashierLayout';
import MainLayout from '../POS/component/pages/SideBar/mainLayout';
import Orders from '../POS/component/pages/orders/order';
import OrderDragNDrop from '../POS/component/pages/orders/orderD&D';
import Menu from '../POS/component/pages/menus/menu';
import SingleOrder from '../POS/component/pages/orders/singleOrder/singleOrder';
import KitchenDisplay from "../POS/KDS/kitchenDisplay"
import CashierMenu from '../POS/Cashier/pages/cashierMenu';
import ThirdParty from '../POS/Customer/thirdParty/thirdParty';
import Costumer from '../POS/component/pages/AdminCustomer/customer';
import AddCostumer from '../POS/component/pages/AdminCustomer/AddCustomer';
import Employee from '../POS/component/pages/Employees/employee';
import CostumerDetails from '../POS/component/pages/AdminCustomer/customerDetail';
import AddEmployee from '../POS/component/pages/Employees/createEmployee';
import EmployeeDetails from '../POS/component/pages/Employees/employeeDetails';
import Restaurant from '../POS/component/pages/Restaurants/restaurant';
import CreateRestaurant from '../POS/component/pages/Restaurants/createRestaurant';
import ItemNavBar from '../POS/component/pages/menus/addItems/ItemNavBar';
import CreateBranch from '../POS/component/pages/Restaurants/createBranch';
import Dashboard from '../POS/component/pages/Dashboard/dashboard';
import OpeningCash from '../POS/Cashier/pages/openCash/openingCash';
import Dispute from '../POS/Cashier/pages/dispute';
import HandOverForm from '../POS/Cashier/components/handOverForm/handOverform';
import PayIn from '../POS/Cashier/pages/payIn';
import PayInForm from '../POS/Cashier/components/PayIn_out_Form/payInForm';
import PayOutForm from '../POS/Cashier/components/PayIn_out_Form/payOutForm';
import OpenCashList from '../POS/Cashier/pages/openCash/openCashList';
import HandOvered from '../POS/Cashier/components/handOverForm/handOverList';
import DayOpeningForm from '../POS/Cashier/components/DayOpening/dayOpeningForm';
import DayOpeningList from '../POS/Cashier/components/DayOpening/DayOpeningList';
import WaiterCall from '../POS/Cashier/components/waiterCall/waiterCall';
import SaleHistory from '../POS/Cashier/components/saleHistory/saleHistory';
import PayLater from '../POS/Cashier/components/saleHistory/payLater';
import UpdateOrder from '../POS/Cashier/components/updateOrder/updateOrder';
import PayOut from '../POS/Cashier/pages/payOut';
import AddCategory from '../POS/component/pages/master/addCategory';
import AddSubCategory from '../POS/component/pages/master/createSubCategory';
import AddVarients from '../POS/component/pages/master/variants';
import CreateModifier from '../POS/component/pages/menus/addItems/createModifiers';
import CreateAddOn from '../POS/component/pages/menus/addItems/createAddOn';
import AddCombos from '../POS/component/pages/menus/addItems/createCombo';
import UploadFile from '../POS/component/pages/master/uploadFile';
import AddCurrency from '../POS/component/pages/master/currencyManagment/currencyManagment';
import TaxManagment from '../POS/component/pages/master/taxManagment';
import AdminMaster from '../POS/component/pages/menus/addItems/createMenuItem';
import ItemDetails from '../POS/component/pages/menus/itemDetails/itemDetail';
import CashOrderDetail from '../POS/Cashier/components/saleHistory/cashOrderDetail';
import KDSLogin from '../POS/component/pages/user/kdsLogin';
import CardPayment from '../POS/Cashier/components/cashierPayment/cardPayment';
import TableMaster from '../POS/component/pages/master/tableMaster/tableMaster';
import Table from '../POS/Cashier/components/Table/table';
import KitchenHistory from '../POS/KDS/kitchenHistory';
import KitchenOrderDetail from '../POS/KDS/detail';
import KitchenMaster from '../POS/component/pages/master/kitchenMaster/kitchenMaster';
import Notification from '../POS/component/pages/setting/Notification';
import PasswordReset from '../POS/component/pages/setting/user';
import CurrencySetting from '../POS/component/pages/setting/currency';
import UserMaster from '../POS/component/pages/master/userMaster/userMaster';
import PaymentManagment from '../POS/component/pages/payment/paymentManagment';
import LandingPage from '../POS/LandingPage/landingPage';
import CurrencyDetail from '../POS/component/pages/master/currencyManagment/currencyDetail';
import Reports from '../POS/component/pages/reports/reports';
import DayCloseForm from '../POS/Cashier/components/DayClose/dayCloseForm';
import ReportGrid from '../POS/component/pages/reports/Grid/reportGrid';
import TotalOrder from '../POS/component/pages/reports/totalOrder';
import OrderHistory from '../POS/component/pages/reports/orderHistory';
import SaleReport from '../POS/component/pages/reports/saleReport/saleReport';
import SaleChart from '../POS/component/pages/reports/saleReport/saleChart';
import ThirdPartReport from '../POS/component/pages/reports/thirdPartReport/thirdPartReport';
import ThirdPartyGrid from '../POS/component/pages/reports/thirdPartReport/thirdPartyGrid';
import SaleDetail from '../POS/component/pages/reports/saleReport/saleDetail';
import DeliveryReport from '../POS/component/pages/reports/DeliveryReport';
import TaxReport from '../POS/component/pages/reports/taxReport';
import CashReport from '../POS/component/pages/reports/cashInReport';
import TableReport from '../POS/component/pages/reports/tableReport';
import CustomerCombo from '../POS/Customer/Combo/combo';
import NotFoundPage from '../POS/LandingPage/notFound/notFound';
import CreateMethod from '../POS/component/pages/master/paymentMethodMaster/createMethod';
import CreateDiscount from '../POS/component/pages/master/discountMaster/discountMaster';
import PromotionMaster from '../POS/component/pages/master/promotionMaster/promotionMaster';
import UserClaim from '../POS/component/pages/master/userRolesMaster/userClaim';
import UserRole from '../POS/component/pages/master/userRolesMaster/userRole';
import MainInventoryLayout from '../INVENTORY/Layouts/mainLayout';
import CreateItem from '../INVENTORY/Pages/itemMaster/createItems';
import UnitMeasurement from '../INVENTORY/Pages/measurementMaster/unitMeasurement';
import LocationMaster from '../INVENTORY/Pages/locationMaster/locationMaster';
import LocationCategoryMaster from '../INVENTORY/Pages/locationMaster/locationCategory';
import ManageUserRole from '../INVENTORY/Pages/userRole/manageUserRole';
import CategoryMaster from '../INVENTORY/Pages/categoryMaster/categoryMaster';
import SubCategoryMaster from '../INVENTORY/Pages/categoryMaster/subCategoryMaster';
import UpdateMenuItem from '../POS/component/pages/menus/addItems/updateMenuItem';
import StockMaster from '../INVENTORY/Pages/stockMaster/stockMaster';
import PackingMaster from '../INVENTORY/Pages/packingMaster/packingMaster';
import InventoryLogin from '../INVENTORY/Components/login/login';
import DashBoard from '../INVENTORY/Pages/dashBoard/dashboard';
import AreaMaster from '../POS/component/pages/master/areaMaster/areaMaster';
import SupplierMaster from '../INVENTORY/Pages/supplier/supplierMaster';
import Listing from '../INVENTORY/Pages/itemMaster/listing';
import PurchaseRequisition from '../INVENTORY/Pages/purchaseRequisition/purchaseRequisition';
import RequisitionListing from '../INVENTORY/Pages/purchaseRequisition/requisitionListing';
import Currency from '../INVENTORY/Pages/currencyMaster/currencyMaster';
import DocumentMaster from '../INVENTORY/Pages/documentMaster/departmentMaster';
import BranchMaster from '../INVENTORY/Pages/branchMaster/branchMaster';
import CreateUserRole from '../INVENTORY/Pages/userRole/createRole';
import SupplierType from '../INVENTORY/Pages/supplierType/supplierType';
import PaymentMaster from '../INVENTORY/Pages/paymentMaster/paymentMaster';
import BankMaster from '../INVENTORY/Pages/bankMaster/bankMaster';
import GRNGrid from '../INVENTORY/Pages/Goods&Receipt/grnList/grnGrid';
import CreateGrn from '../INVENTORY/Pages/Goods&Receipt/grnList/createGrn';
import PriceComparisionGrid from '../INVENTORY/Pages/priceComparison/priceComparisonList';
import PriceComparision from '../INVENTORY/Pages/priceComparison/priceComparison';
import InvoiceList from '../INVENTORY/Pages/invoice/invoiceList';
import CreateInvoice from '../INVENTORY/Pages/invoice/invoiceCreate';
import PurchaseOrderGrid from '../INVENTORY/Pages/purchaseOrder/purchaseOrder';
import CreatePurchaseOrder from '../INVENTORY/Pages/purchaseOrder/createPurchaseOrder';
import PurchaseReturn from '../INVENTORY/Pages/purchaseReturn/purchaseReturn';
import ApprovalUser from '../INVENTORY/Pages/approvalUser/approvalUser';
import Approval from '../INVENTORY/Pages/approval/approval';
import StockTake from '../INVENTORY/Pages/stockTake/stockTake';
import Posting from '../INVENTORY/Pages/posting/posting';
import ApiService from '../POS/services/apiService';
import NewReservation from '../POS/Cashier/components/Table/newReservation';
import UserAccess from '../POS/component/pages/master/userMaster/userAccess';
import PurchaseOrder from '../INVENTORY/Pages/purchaseOrder/PoPrint';
import GrnPrint from '../INVENTORY/Pages/Goods&Receipt/grnList/grnPrint';
import ItemIssueGrid from '../INVENTORY/Pages/issueItems/issueList';
import CreateIssue from '../INVENTORY/Pages/issueItems/createIssue';
import StockAdjustmentList from '../INVENTORY/Pages/stockAdjustment/stockAdjustmentList';
import CreateStockAdj from '../INVENTORY/Pages/stockAdjustment/createStockAdj';
import IssueInventory from '../INVENTORY/Pages/issueInventoryDirect/issueInventory';
import CreateIssueDirect from '../INVENTORY/Pages/issueInventoryDirect/createIssueDirect';
import IssueReturn from '../INVENTORY/Pages/IssueReturn/issueReturnList';
import CreateIssueReturn from '../INVENTORY/Pages/IssueReturn/createIssueReturn';
import IssueReceived from '../INVENTORY/Pages/IssueReceived/IssueReceivedList';
import CreateIssueReceived from '../INVENTORY/Pages/IssueReceived/createIssueReceived';
import DirectPurchaseList from '../INVENTORY/Pages/DirectPurchase/directPurchaseList';
import CreateDirectPurchase from '../INVENTORY/Pages/DirectPurchase/directPurchaseCreate';
import ItemReports from '../INVENTORY/Pages/reports/itemsReports';
import PurchaseOrderReports from '../INVENTORY/Pages/reports/poReports';
import PriceRequisitionReports from '../INVENTORY/Pages/reports/prReports';
import CreateCounter from '../POS/component/pages/master/counterMaster/counterMaster';
import GrnReports from '../INVENTORY/Pages/reports/grnReports';
import PurchaseReturnReport from '../INVENTORY/Pages/reports/purchaseReturnReport';
import InvoiceReport from '../INVENTORY/Pages/reports/invoiceReports';
import RequsitionPrint from '../INVENTORY/Pages/purchaseRequisition/print';
import PurchaseRequisitionView from '../INVENTORY/Pages/purchaseRequisition/viewpage/recositionView';
import PriceCompView from '../INVENTORY/Pages/priceComparison/comparisonView/comparisonView';
import CreatePurchaseReturn from '../INVENTORY/Pages/purchaseReturn/purchaseReturnCreate';
import UpdatePurchaseOrder from '../INVENTORY/Pages/purchaseOrder/updatePurchaseOrder';
import PurchaseOrderView from '../INVENTORY/Pages/purchaseOrder/view';
import GrnView from '../INVENTORY/Pages/Goods&Receipt/grnList/view';
import InvoiceView from '../INVENTORY/Pages/invoice/invoiceView';
import InvoicePrint from '../INVENTORY/Pages/invoice/print';
import CompanyMaster from '../INVENTORY/Pages/companyMaster/company';
import UserMasterINV from "../INVENTORY/Pages/userMaster/userMaster"
import ReturnPrint from '../INVENTORY/Pages/purchaseReturn/prPrint';
import UpdateGrn from '../INVENTORY/Pages/Goods&Receipt/grnList/updateGrn';
import UserStoreList from '../INVENTORY/Pages/userStore/userStoreList';
import UserStoreCreate from '../INVENTORY/Pages/userStore/userStoreCreate';
import StoreTransectionReport from '../INVENTORY/Pages/reports/storeTransectionReport';
import UpdateDirectPurchase from '../INVENTORY/Pages/DirectPurchase/updateDirectPurchase';
import IssueView from '../INVENTORY/Pages/issueItems/issueView';
import UpdateIssueDirect from '../INVENTORY/Pages/issueInventoryDirect/updateIssueDirect';
import UserStoreView from '../INVENTORY/Pages/userStore/userStoreView';
import SupplierGrid from '../INVENTORY/Pages/supplier/supplierList';
import StockAdjView from '../INVENTORY/Pages/stockAdjustment/stockView';
import StoreVarience from '../INVENTORY/Pages/reports/stockVarianceReport';
import SaleOrder from '../SalesTrading/Pages/salesOrder/salesOrder';
import CreateSaleOrder from '../SalesTrading/Pages/salesOrder/createSaleOrder';
import { CurrencyProvider } from '../INVENTORY/Layouts/currencyContext';
import DRNListing from '../SalesTrading/Pages/DRN/DRNList';
import CreateDRN from '../SalesTrading/Pages/DRN/creareDRN';
import CustomerInvoiceListing from '../SalesTrading/Pages/customerInvoice/customerInvoice';
import CreateCustomerInv from '../SalesTrading/Pages/customerInvoice/createCustomerInv';
import QuotationListing from '../SalesTrading/Pages/Quotation/quotationList';
import CreateQuotation from '../SalesTrading/Pages/Quotation/createQuotation';
import MainSalesLayout from '../SalesTrading/Layouts/mainLayout';
import QuotationView from '../SalesTrading/Pages/Quotation/quotationView';
import SalesInvoicePrint from '../SalesTrading/Pages/customerInvoice/print';
import SalesReturn from '../SalesTrading/Pages/salesReturn/salesReturn';
import SaleReturnCreate from '../SalesTrading/Pages/salesReturn/saleReturnCreate';
import QuotationPrint from '../SalesTrading/Pages/Quotation/print';
import SaleOrderPrint from '../SalesTrading/Pages/salesOrder/print';
import DNPrint from '../SalesTrading/Pages/DRN/print';
import SaleOrderView from '../SalesTrading/Pages/salesOrder/view';
import DnView from '../SalesTrading/Pages/DRN/view';
import CustomerInvView from '../SalesTrading/Pages/customerInvoice/invoiceview';
import SalePosting from '../SalesTrading/Pages/posting/posting';



const AppRoutes = () => {
  const role = useSelector(getRole);
  const [userRole, setUserRole] = useState(role);
  const navigate = useNavigate();

  console.log(role, "Role")

  useEffect(() => {

    // if (!userRole) {
    //   window.location.href = 'https://pro360.freemyip.com/';
    // }
    const handleReceiveMessage = (user, message) => {
      if (userRole === "Admin" || userRole === "Cashier")
        toast.success(`${message}`);
    };

    const handleReceiveAck = (user, message) => {
      if (userRole === "Admin" || userRole === "Cashier")
        console.log("Received ")
      // toast.success(`${user}: ${message}`);
    };

    const handleReceiveRejection = (user, message) => {
      if (userRole === "Admin" || userRole === "Cashier")

        toast.error(`Received rejection from ${user}: ${message}`);
    };

    const hubConnection = createHubConnection(
      handleReceiveMessage,
      handleReceiveAck,
      handleReceiveRejection
    );

    return () => {
      hubConnection.stop();
    };
  }, [userRole]);

  return (
    <CurrencyProvider>
      <Routes>
        {!userRole && <>
          {/* Customer routes */}
          {/* <Route path="/" element={<PlainLayout><Splash /></PlainLayout>} /> */}
          {/* <Route path="/" element={<PlainLayout><LandingPage /></PlainLayout>} /> */}
          <Route path="/customer/menu" element={<PlainLayout><CustomerMenu /></PlainLayout>} />
          <Route path="/customer/menu/combo" element={<PlainLayout><CustomerCombo /></PlainLayout>} />
          <Route path="/customer/thirdparty" element={<PlainLayout><ThirdParty /></PlainLayout>} />
          <Route path="/customer/menu/cart" element={<PlainLayout><AddtoCart /></PlainLayout>} />
          <Route path="/customer/menu/cart/payment" element={<PlainLayout><CardDetails /></PlainLayout>} />
          <Route path="/customer/wait" element={<PlainLayout><WaitPage /></PlainLayout>} />
          <Route path="/Login" element={<PlainLayout><InventoryLogin /></PlainLayout>} />
          {/* <Route path="/Login" element={<PlainLayout><Login /></PlainLayout>} /> */}
          <Route path="/Kitchen" element={<PlainLayout><KDSLogin /></PlainLayout>} />
          <Route path="/signup" element={<PlainLayout><Signup /></PlainLayout>} />
        </>
        }
        <Route path="/" element={<MainSalesLayout><DashBoard /></MainSalesLayout>} />

        {/* {userRole === "Admin" && ( */}
          <>
            {/* //Inventory */}
            {/* <Route path="/" element={<MainInventoryLayout><DashBoard /></MainInventoryLayout>} />
            <Route path="/Inventory/Dashboard" element={<MainInventoryLayout><DashBoard /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Item/Create" element={<MainInventoryLayout><CreateItem /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/UnitMeasurement" element={<MainInventoryLayout><UnitMeasurement /></MainInventoryLayout>} />
            <Route path="/inventory/Master/LocationMaster" element={<MainInventoryLayout><LocationMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Location/CategoryMaster" element={<MainInventoryLayout><LocationCategoryMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/UserRole/manage" element={<MainInventoryLayout><ManageUserRole /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/category" element={<MainInventoryLayout><CategoryMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Company" element={<MainInventoryLayout><CompanyMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/SubCategory" element={<MainInventoryLayout><SubCategoryMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Stock" element={<MainInventoryLayout><StockMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Packing" element={<MainInventoryLayout><PackingMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Department" element={<MainInventoryLayout><DocumentMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Branch" element={<MainInventoryLayout><BranchMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/UserRole" element={<MainInventoryLayout><CreateUserRole /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Bank" element={<MainInventoryLayout><BankMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Supplier_Type" element={<MainInventoryLayout><SupplierType /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Payment" element={<MainInventoryLayout><PaymentMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Supplier_Master/:id" element={<MainInventoryLayout><SupplierMaster /></MainInventoryLayout>} />
            <Route path="/Inventory/Supplier_Master/list" element={<MainInventoryLayout><SupplierGrid /></MainInventoryLayout>} />
            <Route path="/Inventory/UserMaster/:id" element={<MainInventoryLayout><UserMasterINV /></MainInventoryLayout>} />
            <Route path="/inventory/Master/Item" element={<MainInventoryLayout><Listing /></MainInventoryLayout>} />
            <Route path="/Inventory/Master/Currency" element={<MainInventoryLayout><Currency /></MainInventoryLayout>} />
            <Route path="/Inventory/Requisition/PurchaseRequisition" element={<MainInventoryLayout><PurchaseRequisition /></MainInventoryLayout>} />
            <Route path="/Inventory/Requisition/:id/:id" element={<MainInventoryLayout><RequsitionPrint /></MainInventoryLayout>} />
            <Route path="/Inventory/Requisition/PurchaseRequisition/:id/:id" element={<MainInventoryLayout><PurchaseRequisitionView /></MainInventoryLayout>} />
            <Route path="/inventory/Requisition/Listing" element={<MainInventoryLayout><RequisitionListing /></MainInventoryLayout>} />
            <Route path="/inventory/GRN/Listing" element={<MainInventoryLayout><GRNGrid /></MainInventoryLayout>} />
            <Route path="/inventory/GRN/Listing/:id/:id" element={<MainInventoryLayout><GrnView /></MainInventoryLayout>} />
            <Route path="/inventory/GRN/Create" element={<MainInventoryLayout><CreateGrn /></MainInventoryLayout>} />
            <Route path="/inventory/GRN/:id/:id" element={<MainInventoryLayout><GrnPrint /></MainInventoryLayout>} />
            <Route path="/inventory/GRN/Update/:id/:id" element={<MainInventoryLayout><UpdateGrn /></MainInventoryLayout>} />
            <Route path="/inventory/PriceComparison/List" element={<MainInventoryLayout><PriceComparisionGrid /></MainInventoryLayout>} />
            <Route path="/inventory/PriceComparison/:id" element={<MainInventoryLayout><PriceComparision /></MainInventoryLayout>} />
            <Route path="/inventory/PriceComparison/View/:id/:id" element={<MainInventoryLayout><PriceCompView /></MainInventoryLayout>} />
            <Route path="/inventory/invoice/List" element={<MainInventoryLayout><InvoiceList /></MainInventoryLayout>} />
            <Route path="/inventory/invoice/Create" element={<MainInventoryLayout><CreateInvoice /></MainInventoryLayout>} />
            <Route path="/inventory/invoice/Print/:id/:id" element={<MainInventoryLayout><InvoicePrint /></MainInventoryLayout>} />
            <Route path="/inventory/invoice/:id/:id" element={<MainInventoryLayout><InvoiceView /></MainInventoryLayout>} />
            <Route path="/inventory/Purchase/Order" element={<MainInventoryLayout><PurchaseOrderGrid /></MainInventoryLayout>} />
            <Route path="/inventory/PurchaseOrder/:id/:id" element={<MainInventoryLayout><PurchaseOrderView /></MainInventoryLayout>} />
            <Route path="/inventory/Purchase/Order/:id" element={<MainInventoryLayout><PurchaseOrder /></MainInventoryLayout>} />
            <Route path="/inventory/Purchase/:id" element={<MainInventoryLayout><UpdatePurchaseOrder /></MainInventoryLayout>} />
            <Route path="/inventory/Purchase/Create" element={<MainInventoryLayout><CreatePurchaseOrder /></MainInventoryLayout>} />
            <Route path="/inventory/PurchaseReturn" element={<MainInventoryLayout><PurchaseReturn /></MainInventoryLayout>} />
            <Route path="/inventory/PurchaseReturn/Print/:id" element={<MainInventoryLayout><ReturnPrint /></MainInventoryLayout>} />
            <Route path="/inventory/PurchaseReturn/Create" element={<MainInventoryLayout><CreatePurchaseReturn /></MainInventoryLayout>} />
            <Route path="/inventory/ApprovalUser" element={<MainInventoryLayout><ApprovalUser /></MainInventoryLayout>} />
            <Route path="/inventory/Approval" element={<MainInventoryLayout><Approval /></MainInventoryLayout>} />
            <Route path="/inventory/StockTake" element={<MainInventoryLayout><StockTake /></MainInventoryLayout>} />
            <Route path="/inventory/Posting" element={<MainInventoryLayout><Posting /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/List" element={<MainInventoryLayout><ItemIssueGrid /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Create" element={<MainInventoryLayout><CreateIssue /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/:id/:id" element={<MainInventoryLayout><IssueView /></MainInventoryLayout>} />
            <Route path="/inventory/Stock/Adjustment" element={<MainInventoryLayout><StockAdjustmentList /></MainInventoryLayout>} />
            <Route path="/inventory/Stock/Adjustment/create" element={<MainInventoryLayout><CreateStockAdj /></MainInventoryLayout>} />
            <Route path="/inventory/Stock/Adjustment/:id/:id" element={<MainInventoryLayout><StockAdjView /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Direct" element={<MainInventoryLayout><IssueInventory /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Direct/Create" element={<MainInventoryLayout><CreateIssueDirect /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Direct/:id/:id" element={<MainInventoryLayout><UpdateIssueDirect /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Return" element={<MainInventoryLayout><IssueReturn /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Return/Create" element={<MainInventoryLayout><CreateIssueReturn /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Received" element={<MainInventoryLayout><IssueReceived /></MainInventoryLayout>} />
            <Route path="/inventory/Issue/Received/create" element={<MainInventoryLayout><CreateIssueReceived /></MainInventoryLayout>} />
            <Route path="/inventory/DirectPurchase" element={<MainInventoryLayout><DirectPurchaseList /></MainInventoryLayout>} />
            <Route path="/inventory/DirectPurchase/Create" element={<MainInventoryLayout><CreateDirectPurchase /></MainInventoryLayout>} />
            <Route path="/inventory/DirectPurchase/Update" element={<MainInventoryLayout><UpdateDirectPurchase /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/Items" element={<MainInventoryLayout><ItemReports /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/PurchaseOrder" element={<MainInventoryLayout><PurchaseOrderReports /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/PurchaseRequisition" element={<MainInventoryLayout><PriceRequisitionReports /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/grn" element={<MainInventoryLayout><GrnReports /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/PurchaseReturn" element={<MainInventoryLayout><PurchaseReturnReport /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/Invoice" element={<MainInventoryLayout><InvoiceReport /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/StockTransection" element={<MainInventoryLayout><StoreTransectionReport /></MainInventoryLayout>} />
            <Route path="/inventory/Reports/stockVarience" element={<MainInventoryLayout><StoreVarience /></MainInventoryLayout>} />
            <Route path="/inventory/UserStore/List" element={<MainInventoryLayout><UserStoreList /></MainInventoryLayout>} />
            <Route path="/inventory/UserStore/Create" element={<MainInventoryLayout><UserStoreCreate /></MainInventoryLayout>} />
            <Route path="/inventory/UserStore/:id/:id" element={<MainInventoryLayout><UserStoreView /></MainInventoryLayout>} /> */}
            {/* Sales Routes */}
            <Route path="/Sales/SaleOrder" element={<MainSalesLayout><SaleOrder /></MainSalesLayout>} />
            <Route path="/Sales/SaleOrder/create" element={<MainSalesLayout><CreateSaleOrder /></MainSalesLayout>} />
            <Route path="/Sales/SaleOrder/Print" element={<MainSalesLayout><SaleOrderPrint /></MainSalesLayout>} />
            <Route path="/Sales/SaleOrder/:id/:id" element={<MainSalesLayout><SaleOrderView /></MainSalesLayout>} />
            <Route path="/Sales/DRN" element={<MainSalesLayout><DRNListing /></MainSalesLayout>} />
            <Route path="/Sales/DRN/create" element={<MainSalesLayout><CreateDRN /></MainSalesLayout>} />
            <Route path="/Sales/DRN/Print" element={<MainSalesLayout><DNPrint /></MainSalesLayout>} />
            <Route path="/Sales/DRN/:id/:id" element={<MainSalesLayout><DnView /></MainSalesLayout>} />
            <Route path="/Sales/Customer/Invoice" element={<MainSalesLayout><CustomerInvoiceListing /></MainSalesLayout>} />
            <Route path="/Sales/Customer/Invoice/Create" element={<MainSalesLayout><CreateCustomerInv /></MainSalesLayout>} />
            <Route path="/Sales/Customer/Invoice/Print" element={<MainSalesLayout><SalesInvoicePrint /></MainSalesLayout>} />
            <Route path="/Sales/Customer/Invoice/:id/:id" element={<MainSalesLayout><CustomerInvView /></MainSalesLayout>} />
            <Route path="/Sales/Quotation" element={<MainSalesLayout><QuotationListing /></MainSalesLayout>} />
            <Route path="/Sales/Quotation/Create" element={<MainSalesLayout><CreateQuotation /></MainSalesLayout>} />
            <Route path="/Sales/Quotation/:id/:id" element={<MainSalesLayout><QuotationView /></MainSalesLayout>} />
            <Route path="/Sales/Quotation/Print" element={<MainSalesLayout><QuotationPrint /></MainSalesLayout>} />
            <Route path="/Sales/Return" element={<MainSalesLayout><SalesReturn /></MainSalesLayout>} />
            <Route path="/Sales/Return/Create" element={<MainSalesLayout><SaleReturnCreate /></MainSalesLayout>} />
            <Route path="/Sales/Posting" element={<MainSalesLayout><SalePosting /></MainSalesLayout>} />

          </>
         {/* )}  */}

        {/* KDS routes */}
        {userRole === 'Kitchen' && (
          <>
            <Route path="/Kitchen/Orders" element={<PlainLayout><KitchenDisplay /></PlainLayout>} />
            <Route path="/Kitchen/History" element={<PlainLayout><KitchenHistory /></PlainLayout>} />
            <Route path="/Kitchen/Order/:id/:name" element={<PlainLayout><KitchenOrderDetail /></PlainLayout>} />
          </>
        )}
        {/* Cashier routes */}
        {userRole === 'Cashier' || userRole === 'Waiter' ? (
          <>
            <Route path="/cashier/home" element={<CashierLayout><CashierMenu /></CashierLayout>} />
            <Route path="/Cashier/ShiftOpen" element={<CashierLayout><OpeningCash /></CashierLayout>} />
            <Route path="/Cashier/OpenCashList" element={<CashierLayout><OpenCashList /></CashierLayout>} />
            <Route path="/Cashier/DayClose" element={<CashierLayout><DayCloseForm /></CashierLayout>} />
            <Route path="/Cashier/HandOvered" element={<CashierLayout><HandOvered /></CashierLayout>} />
            <Route path="/Cashier/Dispute" element={<CashierLayout><Dispute /></CashierLayout>} />
            <Route path="/Cashier/handOverForm" element={<CashierLayout><HandOverForm /></CashierLayout>} />
            <Route path="/Cashier/PayIn" element={<CashierLayout><PayIn /></CashierLayout>} />
            <Route path="/Cashier/PayOut" element={<CashierLayout><PayOut /></CashierLayout>} />
            <Route path="/Cashier/PayInForm" element={<CashierLayout><PayInForm /></CashierLayout>} />
            <Route path="/Cashier/PayOutForm" element={<CashierLayout><PayOutForm /></CashierLayout>} />
            <Route path="/Cashier/DayOpen" element={<CashierLayout><DayOpeningForm /></CashierLayout>} />
            <Route path="/Cashier/DayOpening" element={<CashierLayout><DayOpeningList /></CashierLayout>} />
            <Route path="/Cashier/WaiterOrders" element={<CashierLayout><WaiterCall /></CashierLayout>} />
            <Route path="/Cashier/SaleHistory" element={<CashierLayout><SaleHistory /></CashierLayout>} />
            <Route path="/Cashier/HoldOrders" element={<CashierLayout><PayLater /></CashierLayout>} />
            <Route path="/Cashier/UpdateOrder" element={<CashierLayout><UpdateOrder /></CashierLayout>} />
            <Route path="/Cashier/orderDetails/:id" element={<CashierLayout><CashOrderDetail /></CashierLayout>} />
            <Route path="/Cashier/Payment" element={<CashierLayout><CardPayment /></CashierLayout>} />
            {/* <Route path="/Cashier/Table/Drag" element={<CashierLayout><TableDrag /></CashierLayout>} /> */}
            <Route path="/Cashier/:id" element={<CashierLayout><Table /></CashierLayout>} />
            {/* <Route path="/Cashier/Tables/Reservation" element={<CashierLayout><Reservations /></CashierLayout>} />*/}
            <Route path="/Cashier/Tables/NewReservation" element={<CashierLayout><NewReservation /></CashierLayout>} />
            <Route path="/Cashier/Deliveries" element={<CashierLayout><DeliveryReport /></CashierLayout>} />


          </>
        ) : ''}

        {/* Admin routes */}
        {userRole === 'POSAdmin' && (
          <>
            <Route path="/home" element={<MainLayout><Dashboard /></MainLayout>} />
            <Route path="/admin/orders" element={<MainLayout><Orders /></MainLayout>} />
            <Route path="/admin/ordersDND" element={<MainLayout><OrderDragNDrop /></MainLayout>} />
            <Route path="/admin/additems" element={<MainLayout><ItemNavBar /></MainLayout>} />
            <Route path="/admin/order/:id" element={<MainLayout><SingleOrder /></MainLayout>} />
            <Route path="/admin/menu" element={<MainLayout><Menu /></MainLayout>} />
            <Route path="/admin/menu/:name/:id" element={<MainLayout><ItemDetails /></MainLayout>} />
            <Route path="/admin/CustomerList" element={<MainLayout><Costumer /></MainLayout>} />
            <Route path="/admin/Customer/:Id" element={<MainLayout><CostumerDetails /></MainLayout>} />
            <Route path="/admin/employee/:Id" element={<MainLayout><EmployeeDetails /></MainLayout>} />
            <Route path="/admin/AddCustomer" element={<MainLayout><AddCostumer /></MainLayout>} />
            <Route path="/admin/employee" element={<MainLayout><Employee /></MainLayout>} />
            <Route path="/admin/Addemployee" element={<MainLayout><AddEmployee /></MainLayout>} />
            <Route path="/admin/Restaurant" element={<MainLayout><Restaurant /></MainLayout>} />
            <Route path="/admin/Restaurant/Create" element={<MainLayout><CreateRestaurant /></MainLayout>} />
            <Route path="/admin/Restaurant/Branch/Create" element={<MainLayout><CreateBranch /></MainLayout>} />
            <Route path="/admin/Payment" element={<MainLayout><PaymentManagment /></MainLayout>} />
            {/* Master PAges*/}
            <Route path="/admin/master/Create" element={<MainLayout><AdminMaster /></MainLayout>} />
            <Route path="/admin/master/Update/:id" element={<MainLayout><UpdateMenuItem /></MainLayout>} />
            <Route path="/admin/UserManagement/userMaster/UserRole" element={<MainLayout><UserRole /></MainLayout>} />
            <Route path="/admin/UserManagement/userMaster/UserClaim" element={<MainLayout><UserClaim /></MainLayout>} />
            <Route path="/admin/itemMaster/addCategory" element={<MainLayout><AddCategory /></MainLayout>} />
            <Route path="/admin/itemMaster/subCategory" element={<MainLayout><AddSubCategory /></MainLayout>} />
            <Route path="/admin/itemMaster/Variants" element={<MainLayout><AddVarients /></MainLayout>} />
            <Route path="/admin/itemMaster/UploadFile" element={<MainLayout><UploadFile /></MainLayout>} />
            <Route path="/admin/master/CurrencyManagement" element={<MainLayout><AddCurrency /></MainLayout>} />
            <Route path="/admin/master/Currency/:id" element={<MainLayout><CurrencyDetail /></MainLayout>} />
            <Route path="/admin/master/TaxManagement" element={<MainLayout><TaxManagment /></MainLayout>} />
            <Route path="/admin/itemMaster/addsOns" element={<MainLayout><CreateAddOn /></MainLayout>} />
            <Route path="/admin/itemMaster/modifier" element={<MainLayout><CreateModifier /></MainLayout>} />
            <Route path="/admin/master/paymentMethod" element={<MainLayout><CreateMethod /></MainLayout>} />
            <Route path="/admin/itemMaster/Combos" element={<MainLayout><AddCombos /></MainLayout>} />
            <Route path="/admin/master/Table" element={<MainLayout><TableMaster /></MainLayout>} />
            <Route path="/admin/master/Kitchen" element={<MainLayout><KitchenMaster /></MainLayout>} />
            <Route path="/admin/Setting/Notification" element={<MainLayout><Notification /></MainLayout>} />
            <Route path="/admin/Setting/Profile" element={<MainLayout><PasswordReset /></MainLayout>} />
            <Route path="/admin/Setting/Currency" element={<MainLayout><CurrencySetting /></MainLayout>} />
            <Route path="/admin/master/userMaster/:id" element={<MainLayout><UserMaster /></MainLayout>} />
            {/* <Route path="/admin/master/userMaster/create/:id?" element={<MainLayout><CreateUser /></MainLayout>} /> */}
            <Route path="/admin/master/userMaster/access" element={<MainLayout><UserAccess /></MainLayout>} />
            <Route path="/admin/master/Discount" element={<MainLayout><CreateDiscount /></MainLayout>} />
            <Route path="/admin/itemMaster/CounterMaster" element={<MainLayout><CreateCounter /></MainLayout>} />
            <Route path="/admin/master/Promotion" element={<MainLayout><PromotionMaster /></MainLayout>} />
            <Route path="/admin/master/area" element={<MainLayout><AreaMaster /></MainLayout>} />
            {/* Reports */}
            <Route path="/admin/Reports/home" element={<MainLayout><Reports /></MainLayout>} />
            <Route path="/admin/Reports/Grid" element={<MainLayout><ReportGrid /></MainLayout>} />
            <Route path="/admin/Reports/Order" element={<MainLayout><TotalOrder /></MainLayout>} />
            <Route path="/admin/Reports/OrderHistory" element={<MainLayout><OrderHistory /></MainLayout>} />
            <Route path="/admin/Reports/SaleReport" element={<MainLayout><SaleReport /></MainLayout>} />
            <Route path="/admin/Reports/SaleReport/chart" element={<MainLayout><SaleChart /></MainLayout>} />
            <Route path="/admin/Reports/3rdParty/chart" element={<MainLayout><ThirdPartReport /></MainLayout>} />
            <Route path="/admin/Reports/3rdParty" element={<MainLayout><ThirdPartyGrid /></MainLayout>} />
            <Route path="/admin/Reports/SaleDetails" element={<MainLayout><SaleDetail /></MainLayout>} />
            <Route path="/admin/Reports/Delivery" element={<MainLayout><DeliveryReport /></MainLayout>} />
            <Route path="/admin/Reports/Tax" element={<MainLayout><TaxReport /></MainLayout>} />
            <Route path="/admin/Reports/Cash" element={<MainLayout><CashReport /></MainLayout>} />
            <Route path="/admin/Reports/Table" element={<MainLayout><TableReport /></MainLayout>} />

          </>
        )}
        {/* <Route path="/" element={<PlainLayout><NotFoundPage /></PlainLayout>} /> */}
      </Routes>
    </CurrencyProvider>
  );
};

export default AppRoutes;