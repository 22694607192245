import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import "../Print/print.css"
import { FiPrinter } from "react-icons/fi";
import { PiDownloadThin } from "react-icons/pi";
import moment from 'moment';
// import Logo from "../../Assets/images/Qfinitylogos.png"
import Logo from "../../Assets/Images/Pro_Logo.png"
import { useCurrency } from '../../../INVENTORY/Layouts/currencyContext';
import SaleApiService from '../../services/apiServices';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const QuotationPrint = ({ }) => {
    const location = useLocation();
    const CompareData = location.state;
    const [Items, setItems] = useState([]);
    const currentDatetimeUTC = new Date().toISOString();
    const { currencyPrecision } = useCurrency();

    useEffect(() => {
        setItems(CompareData?.quotationDetails)
    }, [location?.state]);

    const handleBackClick = () => {
        window.history.back();
    };

    const handlePrintPDF = async () => {
        const input = document.getElementById('mainboxdsn');
        if (input) {
            const canvas = await html2canvas(input, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF("landscape");
            pdf.addImage(imgData, 'PNG', 25, 25, 250, 145);
            pdf.save(`Quotation-${CompareData?.quotationNo}.pdf`);
        } else {
            console.error("Element not found: mainboxdsn");
        }
    };
    return (
        <div className='container p-lg-5'>
            <div className='d-flex justify-content-between py-5'>
                <div className='' onClick={handleBackClick} ><button className='prntbtn'> <MdKeyboardDoubleArrowLeft size={20} /> Back</button></div>
                <div className=''><button className='downbtn' onClick={handlePrintPDF}> <PiDownloadThin size={20} /></button></div>
            </div>
            <div className=''>
                <div className='mainboxdsn' id="mainboxdsn">
                    <div className='printhead'>
                        <div className='d-flex justify-content-between'>
                            <div className='center'>
                                <img src={Logo} className="printlogo" alt="Logo" style={{ height: "30px" }} />
                            </div>
                            <div className='mainhead'>
                                <u>Sales Quotation</u>
                            </div>
                            <div className='headtx d-flex align-items-end'>
                                <div className='pt-1'>Date: {moment(CompareData?.quotationDate).format('ll')}</div>
                            </div>
                        </div>
                    </div>
                    <div className='printboxIn p-3 d-flex gap-3'>
                        <div className='printsupbox w-50'>
                            <div className='d-flex justify-content-between'>
                                <div>Date</div>
                                <div>{moment(CompareData?.deliveryDate || currentDatetimeUTC).format('ll')}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Quotation NO</div>
                                <div>{CompareData?.quotationNo}</div>
                            </div>

                            <div className='d-flex justify-content-between'>
                                <div>Departments</div>
                                <div>{CompareData?.department || "---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Location</div>
                                <div>{CompareData?.costCenter1 || "---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Project</div>
                                <div>{CompareData?.quotType || "---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Remarks</div>
                                <div>{CompareData?.remarks || "---"}</div>
                            </div>
                        </div>
                        <div className='printsupbox w-50'>
                            <div className='d-flex justify-content-between'>
                                <div>Customer Name</div>
                                <div>{CompareData?.vendorName || "---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Customer Contact</div>
                                <div>{CompareData?.contractNo || "---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Payment Term</div>
                                <div>{CompareData?.paymentTerms || "---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Job No</div>
                                <div>{"---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Status</div>
                                <div>{CompareData?.status === 1 ?
                                    <div>Approved</div> : <>
                                        {CompareData?.status === 0 ?
                                            <div>Pending</div> : <>
                                                {CompareData?.status === 2 ?
                                                    <div>Hold</div> :
                                                    <>{CompareData?.status === 3 ?
                                                        <div>Rejected</div> : <div>Pending</div>
                                                    }</>
                                                }
                                            </>
                                        }</>
                                }</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Delivery Point</div>
                                <div>{CompareData?.deliveryPoint || "---"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>Cost Center 2</div>
                                <div>{CompareData?.costCenter2 || "---"}</div>
                            </div>
                        </div>

                    </div>
                    <div className='printhead2 mb-5'>
                        <table>
                            <thead className='dynoTable' style={{ backgroundColor: '#5B93FF', color: '#fff' }}>
                                <tr>
                                    <th>Sr no</th>
                                    <th>Item No</th>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>UOM</th>
                                    <th>Unit Price</th>
                                    <th>vat Perc</th>
                                    <th>Vat Amount</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Items?.map((row, index) => (
                                    <tr key={index}>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} type="text" value={index + 1} disabled /></td>
                                        <td className='m-0 p-0'>
                                            <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} disabled value={row.itemCode} /></td>
                                        </td>
                                        <td className='m-0 p-0'>
                                            <td className='m-0 p-0'><input className='p-2' disabled value={row.itemDescription} /></td>
                                        </td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "150px" }} disabled value={row.qty} /></td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "150px" }} disabled value={row.uom} /></td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} disabled value={row.unitPrice.toFixed(currencyPrecision) || 0} /></td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "100px" }} disabled value={row.vatPerc || 0} /></td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "130px" }} disabled value={row.vatAmount.toFixed(currencyPrecision) || 0} /></td>
                                        <td className='m-0 p-0'><input className='p-2' style={{ width: "150px" }} disabled value={(row.amount).toFixed(currencyPrecision) || 0} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='p-5 row'>
                            <div className=' row d-flex justify-content-between'>
                                <div className='col-2'>
                                    <div className='itmKIns center'>Admin</div>
                                    <hr className='' />
                                    <div className='itmKIns center'>Requested By</div>
                                </div>
                                <div className='col-2'>
                                    <div className='itmKIns center'>Admin</div>
                                    <hr className='' />
                                    <div className='itmKIns center'>Reviewed By</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuotationPrint;
