import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ApiService from '../../../INVENTORY/services/apiService';
import { toast } from 'react-toastify';
import { IoAddSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
const ItemsGrid = ({ onRequisitionData, selectedCheck, isDiscountPercentage, itemData }) => {
    const [rows, setRows] = useState([{
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        unitPrice: '',
        InvQty: '',
        amount: '',
        discount: '',
        discountAmount: '',
        convRate: "",
        vat: 0,
        vatDec: '',
        vatAmount: ''
    }]);
    const [itemOptions, setItemOptions] = useState([]);
    const [descriptionOptions, setDescriptionOptions] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [vat, setvat] = useState([]);
    const apiService = new ApiService();
    const netAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVat = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    const totalDiscountAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.discountAmount) || 0), 0)
    const totalDiscount = rows.reduce((sum, row) => sum + (parseFloat(row?.discount) || 0), 0)
    const calculatedTotal = rows.reduce((sum, row) => sum + (parseFloat(row?.amount - row?.vatAmount) || 0), 0)
    useEffect(() => {
        getVat()
        fetchData();
    }, []);
    useEffect(() => {
        const getItems = async () => {
            if (itemData?.saleOrderDetails) {
                const formattedRows = itemData?.saleOrderDetails?.map(detail => {
                    let amount = detail.unitPrice * detail.qty
                    const perAmount = (amount / 100) * detail?.vatPerc
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = selectedCheck ? excludedAmount : amount;
                    const selecteditem = apiData?.find(itm => itm?.itemNo === detail?.itemCode)
                    debugger
                    return {
                        srno: detail?.srno,
                        recordId: detail?.itemCode,
                        ItemNo: detail?.itemCode,
                        itemDescription: detail?.itemDescription,
                        specification: detail?.specification,
                        qty: detail?.qty,
                        uom: detail?.uom,
                        unitPrice: detail?.unitPrice,
                        InvQty: selecteditem?.convToInvUOM * detail?.qty,
                        amount: calculatedAmount,
                        convRate: selecteditem?.convToInvUOM,
                        vat: detail?.vatPerc,
                        vatDec: detail?.vatDesc,
                        vatAmount: detail?.vatAmount
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            } else {
                const formattedRows = itemData?.quotationDetails?.map(detail => {
                    let amount = detail.unitPrice * detail.qty
                    const perAmount = (amount / 100) * detail?.vatPerc
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = selectedCheck ? excludedAmount : amount;
                    const selecteditem = apiData?.find(itm => itm?.itemNo === detail?.itemCode)
                    return {
                        srno: detail?.srno,
                        recordId: detail?.itemCode,
                        ItemNo: detail?.itemCode,
                        itemDescription: detail?.itemDescription,
                        specification: detail?.specification,
                        qty: detail?.qty,
                        uom: detail?.uom,
                        unitPrice: detail?.unitPrice,
                        InvQty: selecteditem?.convToInvUOM * detail?.qty,
                        amount: calculatedAmount,
                        convRate: selecteditem?.convToInvUOM,
                        vat: detail?.vatPerc,
                        vatDec: detail?.vatDesc,
                        vatAmount: detail?.vatAmount
                    };
                }).filter(row => row !== null);
                if (formattedRows?.length > 0) {
                    setRows(formattedRows);
                    onRequisitionData(formattedRows);
                }
            }
        }
        if (apiData && itemData) {
            getItems();
        }
    }, [itemData, selectedCheck, apiData]);
    useEffect(() => {
        const UpdateData = async () => {
            const updatedRows = rows?.map(row => {
                let qty = row.qty;
                let unitPrice = row.unitPrice;
                let updatedAmount = qty * unitPrice;
                let updatedVatAmount = row.vatAmount;
                if (selectedCheck) {
                    updatedAmount = parseFloat(row.amount) + parseFloat(row.vatAmount);
                } else {
                    updatedAmount = parseFloat(row.amount) - parseFloat(row.vatAmount);
                }
                return {
                    ...row,
                    amount: updatedAmount,
                    vatAmount: updatedVatAmount
                };
            });
            setRows(updatedRows);
            onRequisitionData(updatedRows);
        }
        if (!itemData) {
            UpdateData();
        }
    }, [selectedCheck]);
    const fetchData = async () => {
        try {
            const response = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            const selecteditem = response.data.result?.filter(rec => rec.exp9 === '1')
            if (selecteditem?.length > 0) {
                setApiData(selecteditem);
                setItemOptions(selecteditem.map(item => ({
                    value: item.itemNo,
                    label: item.itemNo
                })));
                setDescriptionOptions(selecteditem.map(item => ({
                    value: item.itemDescription,
                    label: item.itemDescription
                })));
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getVat = async () => {
        let param = `?isActive=true`
        const vat = await apiService.getApiParamater('GET_INV_TAX', param);
        setvat(vat.data.result)
    }
    const handleInputChange = (index, fieldName, value) => {
        const isDuplicate = rows.some((row, rowIndex) => rowIndex !== index && (row.ItemNo === value || row.itemDescription === value));
        if (isDuplicate) {
            toast.error('Please choose a different item.');
            return;
        }
        const updatedRows = rows?.map((row, rowIndex) => {
            if (rowIndex === index) {
                const updatedRow = { ...row, [fieldName]: value, srno: ("0" + (index + 1)).slice(-2) };
                if (fieldName === 'itemNo' || fieldName === 'itemDescription') {
                    const selectedItem = apiData.find(item => item[fieldName] === value);
                    const selectedVat = vat.find(item => item?.description === selectedItem.vat);
                    if (selectedItem) {
                        updatedRow.srno = index + 1
                        updatedRow.ItemNo = selectedItem.itemNo;
                        updatedRow.itemDescription = selectedItem?.itemDescription;
                        updatedRow.uom = selectedItem?.baseUnit;
                        updatedRow.convRate = selectedItem.convToInvUOM;
                        updatedRow.vatDec = selectedVat?.description;
                        updatedRow.vat = selectedVat?.rate;
                    }
                }
                if (fieldName === 'specification') {
                    updatedRow.specification = value || '';
                }
                if (fieldName === 'qty') {
                    let invQty = updatedRow.convRate
                    updatedRow.qty = value || 0;
                    updatedRow.InvQty = value * invQty;
                }
                if (fieldName === 'unitPrice') {
                    let qty = updatedRow.qty
                    let amount = value * qty
                    const perAmount = (amount / 100) * updatedRow.vat
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = selectedCheck ? excludedAmount : amount;
                    updatedRow.unitPrice = value;
                    updatedRow.amount = calculatedAmount;
                    updatedRow.vatAmount = perAmount;
                }
                if (fieldName === 'unitPrice' || fieldName === 'qty') {
                    let qty = updatedRow.qty
                    let unitPrice = updatedRow.unitPrice
                    let amount = unitPrice * qty
                    const perAmount = (amount / 100) * updatedRow.vat
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = selectedCheck ? excludedAmount : amount;
                    updatedRow.amount = calculatedAmount;
                    updatedRow.vatAmount = perAmount;
                }
                if (fieldName === 'vat') {
                    let unitPrice = updatedRow.unitPrice
                    let qty = updatedRow.qty
                    let amount = unitPrice * qty
                    const perAmount = (amount / 100) * value.rate
                    let excludedAmount = amount + perAmount
                    let calculatedAmount = selectedCheck ? excludedAmount : amount;
                    updatedRow.amount = calculatedAmount;
                    updatedRow.vatAmount = perAmount;
                    updatedRow.vatDec = value.description;
                    updatedRow.vat = value.rate;
                }
                return updatedRow;
            }
            return row;
        });
        setRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const handleRemoveRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        onRequisitionData(updatedRows);
    };
    const addRow = () => {
        const newRow = {
            srno: '',
            ItemNo: '',
            itemDescription: '',
            branch: '',
            qty: '',
            uom: '',
            unitPrice: '',
            amount: '',
            stock: '',
            convRate: ''
        };
        setRows([...rows, newRow]);
        onRequisitionData([...rows, newRow]);
    };
    return (
        <div>
            <div className='table-container mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th></th>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    {!itemData?.quotationNo ?
                        <tbody>
                            {Array.isArray(rows) && rows.map((row, index) => (
                                <tr key={index}>
                                    <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}>
                                        <div onClick={() => handleRemoveRow(index)} className='center'> <RxCross1 size={15} /></div>
                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.srno} disabled style={{ width: "80px" }} /></td>
                                    <td className='m-0 p-0'>
                                        <Select
                                            options={itemOptions}
                                            value={{ value: row.ItemNo, label: row.ItemNo }}
                                            className="flex-grow-1"
                                            theme={(theme) => ({
                                                ...theme,
                                                padding: 10,
                                            })}
                                            onChange={selectedOption => handleInputChange(index, 'itemNo', selectedOption.value)} />
                                    </td>
                                    <td className='m-0 p-0'>
                                        <Select
                                            options={descriptionOptions}
                                            className="flex-grow-1"
                                            theme={(theme) => ({
                                                ...theme,
                                                padding: 10
                                            })}
                                            value={{ value: row.itemDescription, label: row.itemDescription }}
                                            onChange={selectedOption => handleInputChange(index, 'itemDescription', selectedOption.value)}
                                        />
                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.specification}
                                        style={{ width: "120px" }} onChange={e => handleInputChange(index, 'specification', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()}
                                        value={row.qty} style={{ width: "90px" }} onChange={e => handleInputChange(index, 'qty', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled type="text" style={{ width: "140px" }} value={row.uom} /></td>
                                    <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.InvQty || '...'} style={{ width: "140px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()}
                                        value={row.unitPrice} style={{ width: "120px" }} onChange={e => handleInputChange(index, 'unitPrice', e.target.value)} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="number" onWheel={(e) => e.target.blur()}
                                        value={row.discount} style={{ width: "100px" }} onChange={e => handleInputChange(index, 'discount', e.target.value)} /></td>
                                    <td className='m-0 p-0'>
                                        <Select
                                            className="flex-grow-1"
                                            theme={(theme) => ({
                                                ...theme,
                                                padding: 10
                                            })}
                                            options={vat?.map((sup) => ({ value: sup, label: sup?.description }))}
                                            value={{ value: row.vatDec, label: row.vatDec }}
                                            onChange={selectedOption => handleInputChange(index, 'vat', selectedOption.value)}
                                        />
                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' style={{ width: "120px" }} disabled type="text" value={(row.amount === NaN ? 0 : row.amount || 0) || 0} /></td>
                                </tr>
                            ))}
                        </tbody> :
                        <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}><div className='center'> <RxCross1 size={15} /></div>                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.srno} disabled style={{ width: "80px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.ItemNo} disabled style={{ width: "170px" }} />                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.itemDescription} disabled style={{ width: "180px" }} />                                    </td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.specification} disabled style={{ width: "120px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.qty} disabled style={{ width: "90px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.uom} disabled style={{ width: "140px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.InvQty || 0} disabled style={{ width: "140px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.unitPrice} disabled style={{ width: "120px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.discount} disabled style={{ width: "100px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatDec} disabled style={{ width: "180px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row?.vatAmount || 0} disabled style={{ width: "120px" }} /></td>
                                    <td className='m-0 p-0'><input className='p-2' type="text" value={row.amount || 0} disabled style={{ width: "120px" }} /></td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
                {itemData?.quotationNo ? "" :
                    <div className='d-flex px-2'>
                        <button className='mainbtn  mt-4 gap-3' onClick={addRow}>
                            <IoAddSharp color='#fff' className='center mt-1 mx-1' />
                            <div>Add</div>
                        </button>
                    </div>
                }
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>{`${totalDiscount} ${(isDiscountPercentage === true ? "%" : " ")}` || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{calculatedTotal || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>{totalDiscountAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVat || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{netAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default ItemsGrid;
