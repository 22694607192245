import { React, useState, useEffect } from 'react'
import "./header.css"
import { Navigate, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { connect, useDispatch } from "react-redux"
import Select from "react-select";
import { Link } from 'react-router-dom';
import { logout } from '../../../Redux/actions';
import { useSelector } from 'react-redux';
import { getProfile, getName, getCompany } from '../../../Redux/selecters';
import { RiShutDownLine } from "react-icons/ri";
import Avatar from "../../../INVENTORY/Assets/images/avatar.png"
import { clearCompanies, updateCompany, updateSelectedCompany } from '../../../Redux/actions/companiesAction';
import ApiService from "../../../SalesTrading/services/apiServices";
import { saveLogin, setUserRole } from "../../../Redux/actions";
import userManagement from "../../../POS/services/userManagement";
import { invokeLogin } from "../../../POS/services/signalRService";
import { setCompanies } from "../../../Redux/actions/companiesAction";

const SalesHeader = ({ Currency }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.auth);
    const userName = useSelector(getName);
    const companies = useSelector(getCompany);
    const apiService = new ApiService();
    const image = useSelector(getProfile);
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
        db: '',
        baseDb: ''
    });
    useEffect(() => {
        const url = window.location.href;
        // let url = 'https://proc.qf.freemyip.com/?_username=beU1LHWGkXiCvnk7jE7KQV08vRO9Z/PaYtvikb4gnuI=&_password=+ZhDmciUREwE8CkJMo3bwQ==&_db=13/m5Hq2irSl+D6y0CoZQw==&_baseDb=Ww+NgKyPFMFKKFjxSqBq/Q==';
        const regex = /_username=([^&]+)&_password=([^&]+)&_db=([^&]+)&_baseDb=([^&]+)/;
        const match = url?.match(regex);
        debugger
        if (match) {
            if(localStorage.getItem("Token")){
                return
            }
            setCredentials({
                username: match[1],
                password: match[2],
                db: match[3],
                baseDb: match[4]
            });
            handleLogin({
                username: match[1],
                password: match[2],
                db: match[3],
                baseDb: match[4]
            })
        }
        else if (localStorage.getItem("Token")) {
            // handleLogin();
            return;
        }
        else {
            handleOut();
            return
        }
        // handleLogin()


    }, []);
    useEffect(() => {
        if (!userName) {
            dispatch(clearCompanies());
            // handleOut();
        }
    }, [credentials, userName]);
    const handleLogin = async (first, second, third, fourth) => {
        debugger
        let body = {
            email: credentials?.username || first,
            password: credentials?.password || second,
            basedb: credentials?.baseDb || third,
            supportdb: credentials?.db || fourth
        };
        try {
          
            // const response = await apiService.post("USER_LOGIN", body);
            const response = await apiService.post("GET_ENCRYPT_TOKEN", body);
            if (response && response.status === 200) {
                dispatch(saveLogin(response?.data))
                const userRole = userManagement.getUserRole(response?.data?.data?.accessToken);
                dispatch(setUserRole(userRole))
                localStorage.setItem('Token', response?.data?.data?.accessToken)

                const userCompanies = response?.data?.data?.userCompanies;
                dispatch(setCompanies(userCompanies));
                localStorage.setItem('UserCompanies', JSON.stringify(userCompanies));
                const userId = userManagement.getUserId(response?.data?.data?.userCompanies);
                const InvUserId = userManagement?.getUserId2(response?.data?.data?.accessToken);
                if (userId) {
                    invokeLogin(userId);
                }
            } else if (response.status == false) {
                localStorage.clear();
                window.location.href = 'https://pro360.freemyip.com/';
            }
        } catch (error) {
            debugger
            // if(error.response.status == 404 &&  (body.email.username || body.email.email) && body.email.password){
            //     localStorage.clear();
            //     window.location.href = 'https://pro360.freemyip.com/';  
            // }
            console.log(error,"errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
        }

    };
    useEffect(() => {
        if (companies?.companies?.length) {
            const defaultCompany = companies?.companies[0];
            setSelectedCompany({ label: defaultCompany?.companyName, value: defaultCompany });
            localStorage.setItem('DataBase', defaultCompany?.dataDB);
            dispatch(updateSelectedCompany(defaultCompany));
        }
    }, [companies?.companies, dispatch]);

    const handleDBChange = (selectedOption) => {
        setSelectedCompany(selectedOption);
        dispatch(updateCompany(0, selectedOption.value));
        localStorage.setItem('DataBase', selectedOption?.value?.dataDB);
    };

    const handleOut = () => {
        dispatch(logout());
        dispatch(clearCompanies());
        localStorage.clear();
        window.location.href = 'https://pro360.freemyip.com/';
    };
    const UserName = userName?.length > 20 ? userName.substring(0, 10) + '...' : userName;
    const DocFilter = [
        { value: '----', label: 'From 01 Jan,2024 To 31 Dec,2024' },
        { value: 'Youusuf LLC', label: 'From 01 Jan,2023 To 31 Dec,2023' },
        { value: 'Z PSRED test', label: 'From 01 Jan,2022 To 31 Dec,2022' },
    ];
    return (
        <nav class="navbar-expand-lg  invHead ">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse d-flex end gap-4" id="navbarSupportedContent">
                    <h6 className='col-1 m-0'>Sales Trading</h6>
                    <div className='end col-5 d-flex gap-2'>
                        <div className='center'>Financial Year</div>
                        <Select
                            isSearchable={false}
                            defaultValue={{ label: 'From 01 Jan,2024 To 31 Dec,2024', value: 'From 01 Jan,2024 To 31 Dec,2024' }}
                            options={DocFilter}
                            className="w-50 p-0"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 8,
                                padding: 9
                            })}
                        />
                    </div>
                    <div className='end col-3'>
                        <Select
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 8,
                                padding: 9
                            })}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                    color: state.isFocused ? '#000 ' : '#000',
                                    ':hover': {
                                        backgroundColor: '#F5F5F5',
                                        color: '#000 ',
                                    },
                                }),
                            }}
                            className="w-75 p-0"
                            onChange={handleDBChange}
                            value={selectedCompany}
                            options={companies?.companies?.map(comp => ({ value: comp, label: comp?.companyName }))}
                        />
                    </div>
                    <div className='d-flex gap-2'>
                        <div className="InvProfile dropdown">
                            {UserName || "Admin"}
                            <div className="dropdown-content-stats pt-1">
                                {/* <Link to="/admin/Setting/Profile" className="p-2">Setting</Link> */}
                                <Link onClick={handleOut} className="p-2">Log Out</Link>
                            </div>
                        </div>
                        <div className='invprofileimg'>
                            {image ?
                                <img src={image || Avatar} className='Invboxicon' /> :
                                <img src={Avatar} className='Invboxicon' />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(SalesHeader);