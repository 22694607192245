import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Components/header/header';
import SalesSideLayout from "./sideBarlayout"
const MainSalesLayout = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const isMainLayoutActive = location.pathname === "/Sales/";
        if (isMainLayoutActive) {
            document.body.classList.add('body-with-sidebar');
        } else {
            document.body.classList.remove('body-with-sidebar');
        }
    }, [location.pathname]);

    return (
        <SalesSideLayout>
            <Header />
            <div className='mainbdy'>{children}</div>
        </SalesSideLayout>
    );
};

export default MainSalesLayout;
