import React, { useState, useEffect } from 'react';
import ApiService from '../../../INVENTORY/services/apiService';
import { RxCross1 } from "react-icons/rx";
const QuotItemsView = ({ onQuotationData, itemData, selectedCheck, isDiscountPercentage }) => {
    const [rows, setRows] = useState([{
        srno: '',
        recordId: '',
        ItemNo: '',
        itemDescription: '',
        specification: '',
        qty: '',
        uom: '',
        unitPrice: '',
        InvQty: '',
        amount: '',
        discount: '',
        discountAmount: '',
        convRate: "",
        vat: 0,
        vatDec: '',
        vatAmount: ''
    }]);
    const [apiData, setApiData] = useState([]);
    const apiService = new ApiService();
    const netAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.amount) || 0), 0)
    const totalVat = rows.reduce((sum, row) => sum + (parseFloat(row?.vatAmount) || 0), 0)
    const totalDiscountAmount = rows.reduce((sum, row) => sum + (parseFloat(row?.discountAmount) || 0), 0)
    const totalDiscount = rows.reduce((sum, row) => sum + (parseFloat(row?.discount) || 0), 0)
    const calculatedTotal = rows.reduce((sum, row) => sum + (parseFloat(row?.amount - row?.vatAmount) || 0), 0)

    useEffect(() => {
        const getItems = async () => {
            const response = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            let res = response?.data?.result
            const formattedRows = itemData?.quotationDetails?.map(detail => {
                const selectedItem = res?.find(item => item.itemNo === detail.itemCode);
                let amount = detail.unitPrice * detail.qty
                const perAmount = (amount / 100) * detail?.vatPerc
                let excludedAmount = amount + perAmount
                let calculatedAmount = selectedCheck ? excludedAmount : amount;
                return {
                    srno: detail?.srno,
                    recordId: detail?.itemCode,
                    ItemNo: detail?.itemCode,
                    itemDescription: detail?.itemDescription,
                    specification: detail?.specification,
                    qty: detail?.qty,
                    uom: detail?.uom,
                    unitPrice: detail?.unitPrice,
                    InvQty: selectedItem?.convToInvUOM * detail?.qty,
                    amount: calculatedAmount,
                    convRate: selectedItem?.convToInvUOM,
                    vat: detail?.vatPerc,
                    vatDec: detail?.vatDesc,
                    vatAmount: detail?.vatAmount
                };
            }).filter(row => row !== null);
            if (formattedRows.length > 0) {
                setRows(formattedRows);
                onQuotationData(formattedRows);
            }
        }
        if (itemData) {
            getItems();
        }
    }, [itemData]);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await apiService.getApiParamater('GET_ITEM_PACK', '?isActive=true');
            const selecteditem = response.data.result?.filter(rec => rec.exp9 === '1')
            if (response?.data?.result?.length > 0) {
                setApiData(selecteditem);
            } else {
                console.error('No data found in the API response');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div>
            <div className='table-container mb-5' style={{ overflowX: 'auto', height: "400px" }}>
                <table>
                    <thead className='dynoTable' >
                        <tr>
                            <th></th>
                            <th>Sr no</th>
                            <th>Item No</th>
                            <th>Description</th>
                            <th>Item Specs</th>
                            <th>Qty</th>
                            <th>Packing UOM</th>
                            <th>Inventory Qty</th>
                            <th>Unit Price</th>
                            <th>Discount</th>
                            <th>VAT %</th>
                            <th>VAT Amount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(rows) && rows.map((row, index) => (
                            <tr key={index}>
                                <td className='m-0 p-0' style={{ border: "1px solid rgba(118, 118, 118, 0.3)" }}><div className='center'><RxCross1 size={15} /></div></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row?.srno} style={{ width: "80px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.ItemNo} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.itemDescription} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.specification} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.qty} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.uom} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.InvQty} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.unitPrice} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.discount} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.vatDec} style={{ width: "140px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row?.vatAmount} style={{ width: "120px" }} /></td>
                                <td className='m-0 p-0'><input className='p-2' disabled type="text" value={row.amount} style={{ width: "120px" }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-between boxs'>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Discount</div>
                    <div className='field'>{`${totalDiscount} ${(isDiscountPercentage === true ? "%" : " ")}` || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Amount</div>
                    <div className='field'>{calculatedTotal || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Discount</div>
                    <div className='field'>{totalDiscountAmount || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Total Vat</div>
                    <div className='field'>{totalVat || "---"}</div>
                </div>
                <div className='d-flex gap-2'>
                    <div className="totalhead center">Net Amount</div>
                    <div className='field'>{netAmount || "---"}</div>
                </div>
            </div>
        </div>
    );
};

export default QuotItemsView;
