import React, { useEffect, useState, useRef } from 'react';
import { FaAngleLeft } from "react-icons/fa6";
import Select from 'react-select';
import ItemsGrid from './itemGrid';
import AsyncSelect from 'react-select/async';
import ApiService from "../../services/apiServices"
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
const SaleOrderView = () => {
    const [loader, setLoader] = useState(false);
    const [attachmentFile, setAttachmentFile] = useState(null);
    const fileInputRef = useRef(null);
    const currentDatetimeUTC = new Date().toISOString().slice(0, 16);
    const [SOTab, setSOTab] = useState(true);
    const [CustomerTab, setCustomerTab] = useState(false);
    const [CostTab, setCostTab] = useState(false);
    const [ItemsData, setRequisitionData] = useState([]);
    const [gridVisibility, setGridVisibility] = useState([true, false, false]);
    const [activeTab, setActiveTab] = useState(0);
    const [SoNum, setSONum] = useState();
    const [customerList, setCustomerList] = useState([]);
    const [SelectedCurrency, setSelectedCurency] = useState([]);
    const [Payment, setPayment] = useState([]);
    const [isVatExcluded, setIsVatExcluded] = useState(false);
    const location = useLocation();
    const apiService = new ApiService();
    const itemData = location.state;
    const formatDateToDatetimeLocal = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    useEffect(() => {
        const item = location.state;
        getCustomer()
        const getDocId = async () => {
            try {
                const customer = customerList?.find(dep => dep.customer_Name === item?.vendorName)
                setIsVatExcluded(item?.costCenter5 === "excluded" ? true : false)
                const DocumentDate = item?.docDate ? formatDateToDatetimeLocal(item?.docDate) : getCurrentLocalDatetime();
                const deliveryDate = item?.deliveryDate ? formatDateToDatetimeLocal(item?.deliveryDate) : getCurrentLocalDatetime();
                setPayment({ label: item?.terms, value: item?.terms })
                setSelectedCurency({ label: item?.currencyCode, value: item?.currencyCode })
                setSONum(item?.sOrderNo)
                setFormData({
                    DocumentDate: DocumentDate,
                    deliveryDate: deliveryDate,
                    quotationNo: item?.quotationNo,
                    SellerName: item?.modifiedBy,
                    ContactNo: item?.contatNo,
                    deliveryPoint: item?.deliveryPoint,
                    currencyCode: item?.currencyCode,
                    location: item?.location,
                    contactPerson: item?.contactPerson,
                    remarks: item?.remarks,
                    SupplierContact: item?.contractNo,
                    costCenter1: { label: item?.costCenter1, value: item?.costCenter1 },
                    costCenter2: { label: item?.costCenter2, value: item?.costCenter2 },
                    costCenter3: { label: item?.costCenter3, value: item?.costCenter3 },
                    costCenter4: { label: item?.costCenter4, value: item?.costCenter4 },
                    RequisitionType: item?.soType,
                    exchangeRate: item?.exchangeRate,
                    QuotationReq: item?.quotationRef,
                    Description: item?.description,
                    customerId: { label: item?.vendorId, value: item?.vendorId },
                    customerName: { label: item?.vendorName, value: item?.vendorName },
                    customerNum: customer?.mainContactNo,
                    paymentTerm: { label: item?.terms, value: item?.terms },
                });
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        if (item?.sOrderNo) {
            getDocId()
        }
    }, [location?.state]);
    const getCustomer = async () => {
        try {
            let customerlist = await apiService.get('GET_CUSTOMER')
            setCustomerList(customerlist?.data?.result)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const getCurrentLocalDatetime = () => {
        const localDate = new Date();
        const offset = localDate.getTimezoneOffset() * 60000;
        const localISOTime = new Date(localDate - offset).toISOString().slice(0, 16);
        return localISOTime;
    }
    const [formData, setFormData] = useState({
        PayOrderNo: "",
        remarks: "",
        DocumentDate: getCurrentLocalDatetime(),
        SupplierContact: "",
        deliveryDate: getCurrentLocalDatetime(),
        deliveryPoint: "",
        location: "",
        contactPerson: "",
        quotationNo: "",
        RequisitionType: "",
        ContactNo: "",
        costCenter1: "",
        costCenter2: "",
        costCenter3: "",
        costCenter4: "",
        CostCentre5: "",
        currencyCode: "",
        customerNum: "",
        exchangeRate: "",
        Description: "",
        SellerName: "",
        customerId: "",
        customerName: "",
        OrderType: [],
        paymentTerm: [],
        SupplierDep: [],
    })
    const handleBackClick = () => {
        window.history.back();
    }
    const handleRequisitionData = (data) => {
        setRequisitionData(data);
        console.log(data, 'reqData')
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
        const newGridVisibility = gridVisibility.map((_, index) => index === tabIndex);
        setGridVisibility(newGridVisibility);
    }
    return (
        <div className='px-4'>
            <div className='d-flex justify-content-between pt-3'>
                <h5 className='col mainInvHead'> Sale Order</h5>
                <div className='d-flex gap-3'>
                    <button onClick={handleBackClick} className='mainbtnSecondary'><FaAngleLeft className='center mt-1 mx-2' /><div>Back</div></button>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setSOTab(!SOTab)}>
                    <div className='h6 pt-1'>Sale Order Details</div>
                </div>
                <div className={`slide-content ${SOTab ? 'open' : 'closed'}`}>
                    <div className={`border-box2 p-3 ${SOTab ? 'slide-in' : 'slide-out'}`}>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="InvoiceNO" className="form-label center">Sale Order No *</label>
                                <input
                                    className="form-Input w-70"
                                    disabled value={SoNum}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DeliveryDate" className="form-label center">Delivery Date</label>
                                <input className='form-Input w-70' disabled
                                    id="DeliveryDate" type='datetime' name="DeliveryDate"
                                    value={moment(formData.deliveryDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Currency" className="form-label center">Currency </label>
                                <Select
                                    isSearchable={false} isDisabled
                                    value={SelectedCurrency}
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="DocumentDate" className="form-label center">Document Date</label>
                                <input className='form-Input w-70' disabled
                                    id="DocumentDate" type='datetime' name="DocumentDate"
                                    value={moment(formData.DocumentDate).format('DD-MMM-YYYY')}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="deliveryPoint" className="form-label center">Delivery Point</label>
                                <input
                                    type="text" disabled
                                    className="form-Input w-70"
                                    id="deliveryPoint"
                                    name="deliveryPoint"
                                    value={formData?.deliveryPoint}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ExchangeRate" className="form-label center">Exchange Rate </label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    disabled
                                    id="exchangeRate"
                                    value={formData.exchangeRate}
                                    name="exchangeRate"
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="quotationNo" className="form-label center">Quotation No</label>
                                <input
                                    className="form-Input w-70" name='quotationNo'
                                    id="quotationNo" disabled
                                    value={formData.quotationNo}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="location" className="form-label center">Location</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="location" disabled
                                    name="location"
                                    value={formData.location}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label center">Description</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="Description" disabled
                                    name="Description"
                                    value={formData.Description}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="RequisitionType" className="form-label center">Requisition Type</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="RequisitionType" disabled
                                    name="RequisitionType"
                                    value={formData.RequisitionType}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="contactPerson" className="form-label center">Contact Person</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="contactPerson" disabled
                                    name="contactPerson"
                                    value={formData.contactPerson}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Remarks" className="form-label center">Sale Order Type</label>
                                <Select isDisabled
                                    className="w-70 p-0"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="QuotationReq" className="form-label center">Quotation Req</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="QuotationReq" disabled
                                    name="QuotationReq"
                                    value={formData.QuotationReq}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="ContactNo" className="form-label center">Contact No</label>
                                <input
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="ContactNo"
                                    name="ContactNo" disabled
                                    value={formData.ContactNo}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="Description" className="form-label center">Remarks</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="remarks" disabled
                                    name="remarks"
                                    value={formData.remarks}
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="SellerName" className="form-label center">Seller Name</label>
                                <input
                                    type="text"
                                    className="form-Input w-70"
                                    id="SellerName" disabled
                                    name="SellerName"
                                    value={formData.SellerName}
                                />
                            </div>
                            <div className='col-1 px-5 mx-3'></div>
                            <div className="col-2 brderbox center" style={{ cursor: 'pointer', position: 'relative' }}>
                                <input
                                    type="file"
                                    ref={fileInputRef} disabled
                                    style={{ display: 'none' }}
                                />
                                <div className="file-name">
                                    {attachmentFile ? attachmentFile.name : 'Attachment'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header"
                    onClick={() => setCustomerTab(!CustomerTab)}>
                    <div className='h6 pt-1'>Customer Details</div>
                </div>
                <div className={`slide-content ${CustomerTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row  pb-3">
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Customer ID</label>
                                <AsyncSelect isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerId}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Customer Name</label>
                                <AsyncSelect isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.customerName}
                                />
                            </div>
                            <div className="col d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Payment terms</label>
                                <Select isDisabled
                                    className="w-70 p-0"
                                    value={Payment}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="inventoryAccountCode" className="form-label center">Customer Dept</label>
                                <Select isDisabled
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                />
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label htmlFor="Property" className="form-label center">Customer Contact</label>
                                <input disabled
                                    type="number" onWheel={(e) => e.target.blur()}
                                    className="form-Input w-70"
                                    id="customerNum"
                                    value={formData.customerNum}
                                    name="customerNum"
                                />
                            </div>
                            <div className="col-4 pt-1 d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox" disabled
                                            className="form-check-input"
                                            checked={isVatExcluded}
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Vat Excluded
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input disabled
                                            style={{ width: "26px", height: "26px" }}
                                            type="checkbox"
                                            className="form-check-input"
                                        // checked={isDiscountPercentage}
                                        // onChange={handleDiscountChange}
                                        />
                                        <label className="form-check-label mx-2 mt-1">
                                            Discount in %
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cost-center-section">
                <div className="d-flex mt-3 cursor-pointer slide-header" onClick={() => setCostTab(!CostTab)}>
                    <div className="h6 pt-1">Cost Center Details</div>
                </div>
                <div className={`slide-content ${CostTab ? 'open' : 'closed'}`}>
                    <div className="border-box2 p-3">
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter1" className="form-label me-2">Property Group</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter1} isDisabled
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter2" className="form-label me-2">Property</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter2} isDisabled
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter3" className="form-label me-2">Department</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter3} isDisabled
                                    cacheOptions
                                />
                            </div>
                        </div>
                        <div className="form-group row pb-3">
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter4" className="form-label me-2">Outlet</label>
                                <AsyncSelect
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    className="w-70 p-0"
                                    value={formData.costCenter4}
                                    cacheOptions
                                    isDisabled
                                />
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                                <label htmlFor="costCenter5" className="form-label me-2">Cost Centre 5</label>
                                <Select
                                    id="costCenter5" className="flex-grow-1"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        padding: 9
                                    })}
                                    menuPortalTarget={document.body} isDisabled
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                />
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slide-container p-2 mt-3">
                <div className="container-fluid">
                    <div className="nav nav-pills overslid d-flex gap-4">
                        {/* Nav tabs */}
                        <div
                            className={`dashitems sidebar-text center ${activeTab === 0 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(0)}
                        >
                            Items
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 1 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Authorization
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 2 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Terms
                        </div>
                        <div
                            className={`dashitems sidebar-text center  ${activeTab === 3 ? 'sideActive' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Recommendation
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-1 brderbox2 mb-3">
                {gridVisibility[0] && (
                    <ItemsGrid onRequisitionData={handleRequisitionData} selectedCheck={isVatExcluded} itemData={itemData} />
                )}
            </div>
        </div>
    );
};
export default SaleOrderView; 